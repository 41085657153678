import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const listAddOns = createAsyncThunk(
  "addOns/list",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/addon/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const detailAddOns = createAsyncThunk(
  "addOns/detail",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/addon/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAddOns = createAsyncThunk(
  "addOns/delete",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/addon/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAddOns = createAsyncThunk(
  "addOns/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/addon/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAddOns = createAsyncThunk(
  "addOns/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/addon/${data.idAddOns}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);