import { TextField, Grid, InputAdornment } from '@material-ui/core';
import React from 'react';
import { ArrowLeft, Search, Bell, User } from 'react-feather';
import { useHistory } from 'react-router-dom';

function Navbar(props) {
    let history = useHistory()
    return (
        <div className="navbar">
            <Grid md={4}>
                <TextField
                    className='search'
                    id="input-with-icon-textfield"
                    // label="TextField"
                    variant='outlined'
                    size='small'
                    placeholder='Cari Nomor Pesanan'
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <Search />
                        </InputAdornment>
                    ),
                    }}
                />
            </Grid>
            {/* <div className="bread">
                    {
                        props.backLink ?
                         <ArrowLeft onClick={()=> history.push(props.backLink)} style={{cursor: 'pointer'}} /> : null 
                    }
                    &nbsp;{props.title}
                    
            </div> */}
            <div className="menus">
                {/* <div className="menu"><Search /></div> */}
                <div className="menu"><Bell/></div>
                <div className="menu"><User /> <span>Admin</span> </div>
                
            </div>
        </div>  
    );
}

export default Navbar;