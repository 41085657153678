import React from "react";
import moment from "moment";
import 'moment/locale/id';
// import actions from "../../../../../store/reducers/transaction/actions";
import {Badge } from "../../../../components";
import { CheckedBox } from "../../../../assets/icon";
import "../../styles.scss"
const OrderNew = ({ OrderData, onClick }) => {
  return (
    <React.Fragment>
      <div className="transaction-list">
        <div className="transaction-header">
          <div>
            <div><Badge variant={
                  OrderData.status === "Dibatalkan" ? ("contrast-red")
                  :OrderData.status === "Pemasangan Selesai" ? ("green")
                  :OrderData.status === "Diproses" ? ("yellow")
                  :OrderData.status === "Dikirim" ? ("red")
                  :OrderData.status === "Siap Dipasang" ? ("blue")
                  :OrderData.status === "Proses Pemasangan" ? ("blue")
                  :OrderData.status === "Selesai" ? ("green")
                  :OrderData.status === "Belum Bayar" ? ("grey-dark"):("grey")
            }>{OrderData.status}</Badge></div>
            <div>
              <p>Pelanggan:</p>
              <p style={{fontWeight:"bold"}}>{OrderData.customer_name}</p>
            </div>
            <div>
              <p>Nomor Pesanan:</p>
              <p style={{fontWeight:"bold"}}>{OrderData.order_number}</p>
            </div>
            <div>
              <p>Waktu Pesanan Dibuat:</p>
              <p style={{fontWeight:"bold"}}>{moment(OrderData.created_at).format("dddd, Do MMMM YYYY")}</p>
            </div>
            <div>
              <p>Methode Pengiriman:</p>
              <p style={{fontWeight:"bold"}}>{OrderData.order_shipment.courier_name}</p>
            </div>
          </div>
          <div className="action" onClick={onClick}>Lihat Detail</div>
        </div> 
        <div className="transaction-body">
          <div>
              <img src={OrderData.order_product[0].product_image} alt="" />
              <div className="brand">
                <div>{OrderData.order_product[0].brand_name}</div>
                <div>{OrderData.order_product[0].product_name}</div>
                {OrderData.count_another_product > 0 ? (
                  <div>{`+${OrderData.count_another_product} Product Lainnya`}</div>
                ):(null)}
              </div>
          </div>
          <div className="grey">
            <div className="addonsTitle">Add-On Service</div>
            <div className="addons">
              {OrderData.order_addons?.map((val,index)=>{
                return(
                  <div key={index}><CheckedBox/> {val.addon_type}</div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderNew;
