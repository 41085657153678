import axios from "axios";
import { useMutation, useQuery } from "react-query";

export function useNotificationList(params) {
  return useQuery({
    queryKey: ["notification-list"],
    queryFn: async () => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications`,
        {
          params,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
  });
}

export function useNotificationUpdate({ onSuccess, onError } = {}) {
  return useMutation(
    ["notification-update"],
    async ({ id, value }) => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/notifications/read/${id}`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}

export function useNotificationsReadAll({ onSuccess, onError } = {}) {
  return useMutation(
    ["notification-read-all"],
    async (value) => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/notifications/read-all`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}

export function useNotificationBlastID(id) {
  return useQuery({
    queryKey: ["notification-blast", id],
    queryFn: async () => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications/blast/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.data;
    },
  });
}
