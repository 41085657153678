import React, { useEffect, useState } from "react";
import { AdminNav } from "components";
import "./styles.scss";
import DetailCard from "./DetailCard/index";
import Template from "pages/shared/Template";

const NotificationCenterDetail = () => {
  const component = () => {
    return (
      <>
        <AdminNav pageTitle="Back" backIcon />
        <div className="p-4">
          <DetailCard />
        </div>
      </>
    );
  };
  return (
    <Template
      noNavbar
      isSideNav
      title="Notification Center Detail"
      component={component}
    />
  );
};

export default NotificationCenterDetail;
