import { createSlice } from "@reduxjs/toolkit";
import { postLogin } from "./login.api";


const loginSlice = createSlice({
    name : 'login',
    initialState : {
        login :{},
        loading : false,
        error: {}
    },
    extraReducers : {
        [postLogin.pending]: (state, action)=>{
            state.loading = true
        }, 
        [postLogin.fulfilled]: (state, {payload})=>{
            state.loading = false
        }, 
        [postLogin.rejected]: (state, action)=>{
            state.loading = false
        },  
    }

})

export default loginSlice.reducer