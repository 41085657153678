import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import "antd/dist/antd.css";
import { unwrapResult } from "@reduxjs/toolkit";
import ImageUploading from "react-images-uploading";

import { useDispatch, useSelector } from "react-redux";

import LayoutStockV2 from "../../components/Shared/LayoutStockV2";
import { Modal } from "../../components";
import { Button, Card, FormGroup, Input, Dropdown } from "../../components";

import { createDealer } from "./dealer.api";
import { uploadFile } from "../../services/fileService";
import { getListRegency } from "../region/regency.api";
import { getListDistrict } from "../region/district.api";

import "./styles.scss";
import Template from "../shared/Template";

export default function DealerForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false);
  const { regencyOptions } = useSelector((state) => state.regency);
  const { districtOptions } = useSelector((state) => state.district);

  const handleRegencyClick = (value) => {
    formik.setFieldValue("district_id", "");
    formik.setFieldValue("regency_id", value);
    dispatch(getListDistrict(value));
  };

  const handleDistrictClick = (value) => {
    formik.setFieldValue("district_id", value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      photo_url: [],
      pic_name: "",
      email: "",
      phone: "",
      address: "",
      regency_id: "",
      district_id: "",
      postal_code: "",
      lat: "",
      lon: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      photo_url: Yup.array().min(1).required("Photo is required"),
      pic_name: Yup.string().required("PIC name is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      address: Yup.string().required("Address is required"),
      // regency_id: Yup.string().required('Regency is required'),
      // district_id: Yup.string().required('District is required'),
      postal_code: Yup.string().required("Postal code is required"),
      lat: Yup.number().required("Latitude is required"),
      lon: Yup.number().required("Longitude is required"),
    }),
    onSubmit: (values) => {
      const {
        name,
        description,
        photo_url,
        pic_name,
        email,
        phone,
        address,
        regency_id,
        district_id,
        postal_code,
        lat,
        lon,
      } = values;

      const payload = {
        name,
        description,
        photo_url: "",
        pic_name,
        email,
        phone,
        address,
        regency_id,
        district_id,
        postal_code,
        lat,
        lon,
      };
      const payloadFile = {
        file: photo_url[0].file,
        path: "brands",
        overwrite: false,
        previous_file: "",
      };
      dispatch(uploadFile(payloadFile))
        .then((response) => {
          payload.photo_url = response.payload.data.file_url;
          dispatch(createDealer(payload))
            .then(unwrapResult)
            .then((response) => {
              console.info(response, "Success create dealer");
              setIsSuccessModalActive(true);
            })
            .catch((err) => {
              console.error(err, "Failed create dealer");
              alert("Create dealer was failed", err);
            });
        })
        .catch((err) => {
          console.error(err);
          alert("Failed create a dealer", err);
        });
    },
  });

  const { handleSubmit } = formik;

  const cancel = () => {
    history.push("/dealer-list");
  };

  useEffect(() => {
    dispatch(getListRegency());
  }, [dispatch]);

  let component = () => {
    return (
      <LayoutStockV2 isNavAdmin={true} title="Add Dealer">
        <section className="create-product__content">
          <form onSubmit={handleSubmit}>
            <Card>
              <Card.Content title="Dealer Information">
                <div className="content__product-information">
                  <div className="form-size--half">
                    <FormGroup>
                      <Input
                        type="text"
                        id="name"
                        label="Dealer Name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.errors.name ? (
                        <div className="label-validation-error">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        id="pic_name"
                        label="PIC Name"
                        name="pic_name"
                        onChange={formik.handleChange}
                        value={formik.values.pic_name}
                      />
                      {formik.errors.pic_name ? (
                        <div className="label-validation-error">
                          {formik.errors.pic_name}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="form-size--half">
                    <FormGroup>
                      <Input
                        type="text"
                        id="email"
                        label="Email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email ? (
                        <div className="label-validation-error">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        id="phone"
                        label="Phone"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                      {formik.errors.phone ? (
                        <div className="label-validation-error">
                          {formik.errors.phone}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="form-size--half">
                    <FormGroup>
                      <Input
                        type="text"
                        id="address"
                        label="Address"
                        name="address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                      />
                      {formik.errors.address ? (
                        <div className="label-validation-error">
                          {formik.errors.address}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Dropdown
                        label="Regency"
                        placeholder="Select Regency"
                        items={regencyOptions}
                        onChange={handleRegencyClick}
                        selectedItem={formik.values.regency_id}
                      />
                      {formik.errors.regency_id ? (
                        <div className="label-validation-error">
                          {formik.errors.regency_id}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="form-size--half">
                    <FormGroup>
                      <Dropdown
                        label="District"
                        placeholder="Select District"
                        items={districtOptions}
                        onChange={handleDistrictClick}
                        selectedItem={formik.values.district_id}
                      />
                      {formik.errors.district_id ? (
                        <div className="label-validation-error">
                          {formik.errors.district_id}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        id="postal_code"
                        label="Postal Code"
                        name="postal_code"
                        onChange={formik.handleChange}
                        value={formik.values.postal_code}
                      />
                      {formik.errors.postal_code ? (
                        <div className="label-validation-error">
                          {formik.errors.postal_code}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="form-size--half">
                    <FormGroup>
                      <Input
                        type="number"
                        id="lat"
                        label="Latitude"
                        name="lat"
                        onChange={formik.handleChange}
                        value={formik.values.lat}
                      />
                      {formik.errors.lat ? (
                        <div className="label-validation-error">
                          {formik.errors.lat}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="number"
                        id="lon"
                        label="Longitude"
                        name="lon"
                        onChange={formik.handleChange}
                        value={formik.values.lon}
                      />
                      {formik.errors.lon ? (
                        <div className="label-validation-error">
                          {formik.errors.lon}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <Input
                      type="textarea"
                      id="description"
                      label="Description"
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                    {formik.errors.description ? (
                      <div className="label-validation-error">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </FormGroup>
                </div>
              </Card.Content>
            </Card>
            <Card className="mt-6">
              <Card.Content title="Dealer Photo">
                <div>
                  <label className="label">Photo</label>
                  <ImageUploading
                    value={formik.values.photo_url[0]}
                    onChange={(val) => formik.setFieldValue("photo_url", val)}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div
                        className="upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center"
                        style={{ minHeight: 100, maxWidth: 200 }}
                      >
                        <button
                          type="button"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          {!formik.values.photo_url.length &&
                            "Click or Drop here"}
                          {formik.values.photo_url.map((image, index) => (
                            <img
                              key={index}
                              src={image["data_url"]}
                              alt=""
                              width="100"
                            />
                          ))}
                        </button>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </Card.Content>
            </Card>

            <div className="content__actions">
              <Button
                variant="secondary"
                type="button"
                textVariant="big"
                onClick={() => cancel()}
              >
                Cancel
              </Button>
              <Button variant="big" type="submit">
                Save
              </Button>
            </div>
          </form>
        </section>
        <Modal
          type="success"
          title="Dealer Created Successfully"
          isModalActive={isSuccessModalActive}
          onModalClosed={() => history.push("/dealer-list")}
          isLoading={false}
        />
      </LayoutStockV2>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
}
