import React, { useEffect, useRef, useState } from "react";
// import moment from "moment";
import queryString from "query-string";
// import ReactDatePicker from "react-datepicker";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AdminNav, Tab } from "../../components";
import Template from "../shared/Template";
import OrderNew from "./components/Order";
import { getListTransaction } from "./transaction.api";
import "./styles.scss";

const TransactionManagement = () => {
  const tabs = [
    {
      name: "not-paid",
      label: "Belum Bayar",
    },
    {
      name: "order-new",
      label: "Pesanan Baru",
    },
    {
      name: "on-process",
      label: "Diproses",
    },
    {
      name: "delivery",
      label: "Pengiriman",
    },
    {
      name: "ready-to-rock",
      label: "Siap Dipasang",
    },
    {
      name: "installing",
      label: "Proses Pemasangan",
    },
    {
      name: "done-installing",
      label: "Pemasangan Selesai",
    },
    {
      name: "complete",
      label: "Selesai",
    },
    {
      name: "refund",
      label: "Refund",
    },
    {
      name: "refund-processing",
      label: "Refund Diproses",
    },
    {
      name: "refund-finished",
      label: "Refund Selesai",
    },
  ];

  const dispatch = useDispatch();

  const { loading, totalItem } = useSelector((state) => state.transaction);
  const [transactionData, setTransactionData] = useState([]);
  const [waitFetch, setWaitFetch] = useState(false);
  const [params, setParams] = useState({
    order: "created_at,desc",
    limit: 5,
    page: 1,
    deliv_loc: "dealer",
    q: null,
    status: 2,
    start_date: "",
    end_date: "",
  });

  const location = useLocation();
  const history = useHistory();

  const { target } = queryString.parse(location.search);

  const [activeTab, setActiveTab] = useState(
    target !== undefined ? target : "order-new"
  );

  useEffect(() => {
    if (!waitFetch) {
      dispatch(getListTransaction(params))
        .unwrap()
        .then((res) => {
          setTransactionData(res.data.transactions);
        });
    }
    const timer = setTimeout(() => {
      dispatch(getListTransaction(params))
        .unwrap()
        .then((res) => {
          setTransactionData(res.data.transactions);
        });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, params]);

  const handleTabChanges = (name) => {
    setActiveTab(name);
    let statusValue;
    switch (name) {
      case "order-new":
        statusValue = 2;
        break;
      case "not-paid":
        statusValue = 1;
        break;
      case "on-process":
        statusValue = 3;
        break;
      case "delivery":
        statusValue = 4;
        break;
      case "ready-to-rock":
        statusValue = 7;
        break;
      case "installing":
        statusValue = 8;
        break;
      case "done-installing":
        statusValue = 9;
        break;
      case "refund":
        statusValue = 10;
        break;
      case "refund-processing":
        statusValue = 11;
        break;
      case "refund-finished":
        statusValue = 12;
        break;
      case "complete":
        statusValue = 5;
        break;
      default:
        statusValue = 2;
        break;
    }
    setParams({
      ...params,
      status: statusValue,
    });
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (transactionData.length >= totalItem) {
        return;
      }
      const newParams = {
        ...params,
        page: params.page + 1,
      };
      dispatch(getListTransaction(newParams))
        .unwrap()
        .then((res) => {
          setTransactionData([...transactionData, ...res.data.transactions]);
        });
    }
  };
  const scrollableTabsRef = useRef(null);
  const scrollStep = 200;
  const scrollSpeed = 10;

  const scrollRight = () => {
    if (scrollableTabsRef.current) {
      scrollableTabsRef.current.scrollBy({
        left: scrollStep,
        behavior: "smooth",
      });
    }
  };

  const scrollLeft = () => {
    if (scrollableTabsRef.current) {
      scrollableTabsRef.current.scrollBy({
        left: -scrollStep,
        behavior: "smooth",
      });
    }
  };

  console.log(transactionData);
  let component = () => {
    return (
      <div className="transaction-management">
        <AdminNav
          search
          onChange={(e) => {
            setWaitFetch(true);
            setParams({
              ...params,
              page: 1,
              q: e.target.value,
            });
          }}
        />

        <div className="transaction-management__header">
          <button onClick={scrollLeft}>
            <img
              src="https://static.thenounproject.com/png/1363414-200.png"
              alt=""
              srcset=""
              style={{ width: "40px", height: "40px" }}
            />
          </button>
          <div className="scrollable-tabs" ref={scrollableTabsRef}>
            <Tab
              dataTabs={tabs}
              activeTab={activeTab}
              onTabChange={handleTabChanges}
            />
          </div>
          <button onClick={scrollRight}>
            <img
              src="https://static.thenounproject.com/png/1480289-200.png"
              alt=""
              srcset=""
              style={{ width: "50px", height: "50px" }}
            />
          </button>
        </div>
        <div className="transaction-management__content">
          {transactionData?.length > 0 ? (
            <>
              {transactionData?.map((transaction, index) => {
                return (
                  <OrderNew
                    key={index}
                    OrderData={transaction}
                    onClick={() => {
                      history.push(`transaction-management/${transaction.id}`);
                    }}
                  />
                );
              })}
            </>
          ) : loading && transactionData?.length === 0 ? null : (
            <div style={{ margin: "auto", width: "max-content" }}>
              Tidak Ditemukan
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <Template
      noNavbar
      isSideNav
      scrollEvent
      onScroll={handleScroll}
      isStitle="Transaksi"
      component={component}
    />
  );
};

export default TransactionManagement;
