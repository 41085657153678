import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Row, Form, Col, Badge } from "react-bootstrap";
import { DeleteFilled } from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";

import { getDetailDealer } from "../dealer.api";
import { getDetailAdmin, deleteAdmin } from "../adminManagement.api";

import { Card, AdminNav, Button, Modal } from "../../../components";
import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";
import Template from "../../shared/Template";

const DetailAdmin = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDetailAdmin(id)).then((res) => {
      const resData = res.payload.data;

      if (resData.type === "dealer") {
        dispatch(getDetailDealer(resData.dealer_id));
      }
    });
  }, [dispatch, id]);
  
  const [isConfirmModalActive, setIsConfirmModalActive] = useState(false);
  const [adminDelete, setAdminDelete] = useState({});

  const { detailAdmin, loading } = useSelector((state) => state.admin);

  const { detailDealer } = useSelector((state) => state.dealer);

  const handleOpenModalDelete = (obj) => {
    setAdminDelete(obj);
    setIsConfirmModalActive(true);
  };

  const handleCloseModalDelete = () => {
    setIsConfirmModalActive(false);
    setAdminDelete({});
  }

  const handleOrderRequest = () => {
    handleCloseModalDelete();
    dispatch(deleteAdmin(adminDelete.id)).then(() => {
      setTimeout(() => {
        history.push(`/admin-management`)
      }, 600);
    });
  };

  const breadcrumbItems = [
    {
      title: "Admin Management",
      to: "/admin-management",
    },
    {
      title: "Detail Admin",
      noIcon: "noIcon",
    },
  ];

  const fieldMapping = [
    {
      title: "Nama",
      value: detailAdmin && detailAdmin.name,
    },
    {
      title: "NIK",
      value: detailAdmin && detailAdmin.nik,
    },
    {
      title: "Email",
      value: detailAdmin && detailAdmin.email,
    },
    {
      title: "Status",
      value: detailAdmin && detailAdmin.is_active,
    },
    {
      title: "Role",
      value:
        detailAdmin && detailAdmin.type === "admin"
          ? "Admin HQ"
          : "Admin Dealer",
    },
  ];

  let component = () => {
    return (
      <>
        <AdminNav breadcrumbItems={breadcrumbItems} />

        <LayoutStockV2>
          {loading ? (
            <Card.Content>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Card.Content>
          ) : (
            <Card.Content
              title={`Detail Admin ${detailAdmin && detailAdmin.name}`}
            >
              <Row>
                {fieldMapping.map((item, key) => (
                  <Form.Group as={Col} className="mb-4" md={6} key={key}>
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {item.title}
                    </Form.Label>
                    {item?.title?.toLocaleLowerCase() === "status" ? (
                      <div className="field-name">
                        <Badge
                          style={{
                            textTransform: "capitalize",
                            width: "100px",
                            fontSize: "12px",
                          }}
                          pill
                          text="light"
                          bg={item?.value ? "success" : "danger"}
                        >
                          {item.value ? "Active" : "Inactive"}
                        </Badge>
                      </div>
                    ) : (
                      <div className="field-name">{item.value}</div>
                    )}
                  </Form.Group>
                ))}
                {detailAdmin && detailAdmin.type === "dealer" ? (
                  <Form.Group as={Col} className="mb-4" md={6}>
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Nama Dealer
                    </Form.Label>
                    <div className="field-name">
                      {detailDealer && detailDealer.name}
                    </div>
                  </Form.Group>
                ) : null}
                <Button variant="full" onClick={() => history.push(`/edit-admin-management/${detailAdmin.id}`)}>
                  <span style={{ color: "#fff" }}>Edit</span>
                </Button>
                <Button variant="white" onClick={() => handleOpenModalDelete(detailAdmin)}>
                  <DeleteFilled/><span style={{ color: "#E9522A" }}>Hapus</span>
                </Button>
              </Row>
            </Card.Content>
          )}
        </LayoutStockV2>

        <Modal
            type="confirm"
            title={`Apakah Anda Yakin Ingin Menghapus Admin ${adminDelete.name}?`}
            buttonText="Hapus Admin"
            isModalActive={isConfirmModalActive}
            onModalClosed={() => handleCloseModalDelete()}
            onModalConfirmed={handleOrderRequest}
            isLoading={false}
        />
      </>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default DetailAdmin;
