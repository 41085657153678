import React, { useEffect, useState } from "react";
import { Table, Pagination } from "antd";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { getListAdmin } from "./adminManagement.api";
import { AdminNav, Button, SearchInput, Select } from "../../components";
// import { DeleteFilled, EditFilled, EyeFilled } from "@ant-design/icons";

import "./styles.scss";
import Template from "../shared/Template";

const AdminManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [limitPageSize, setLimitPageSize] = useState(5);

  const [params, setParams] = useState({
    dealer_id: null,
    name: null,
    role: "admin,dealer",
    limit: limitPageSize,
    offset: 0,
    order: "createdAt,desc",
  });

  const sortOptions = [
    { label: "Terbaru", value: "createdAt,desc" },
    { label: "Terlama", value: "createdAt,asc" }
  ];

  const activeOptions = [
    { label: "Active", value: 1},
    { label: "Inactive", value: 2 }
  ];


  const limitOptions = [
    { label: "5 Entri", value: 5 },
    { label: "10 Entri", value: 10 },
    { label: "25 Entri", value: 25 },
    { label: "50 Entri", value: 50 },
  ];

  // const [isConfirmModalActive, setIsConfirmModalActive] = useState(false);
  // const [adminDelete, setAdminDelete] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  // const handleOpenModalDelete = (obj) => {
  //   setAdminDelete(obj);
  //   setIsConfirmModalActive(true);
  // };

  // const handleCloseModalDelete = () => {
  //   setIsConfirmModalActive(false);
  //   setAdminDelete({});
  // };

  // const handleOrderRequest = () => {
  //   handleCloseModalDelete();
  //   dispatch(deleteAdmin(adminDelete.id)).then(() => {
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 600);
  //   });
  // };

  const handlePagination = (value) => {
    setCurrentPage(value);

    setParams({
      ...params,
      offset: (value - 1) * params.limit,
    });
  };

  useEffect(() => {
    return dispatch(getListAdmin(params));
  }, [dispatch, params]);

  const { listAdmin, loading } = useSelector((state) => state.admin);

  const columns = [
    {
      title: "ID Admin",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nama Admin",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, obj) => <Link to={'/detail-admin-management/' + obj.id}>{text}</Link>
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      key: "email",
    },
    {
      title: "No. Telepon",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Role",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      fixed: 'right',
      render: (item) => (
        <Badge
          style={{
            textTransform: "capitalize",
            width: "100px",
            fontSize: "12px",
          }}
          pill
          bg={item ? "success" : "danger"}
          text="light"
        >
          {item ? "Active" : "Inactive"}
        </Badge>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "operation",
    //   render: (_, obj) => (
    //     <div className="d-flex w-100">
    //       <button
    //         className="mx-2"
    //         onClick={() => history.push(`/detail-admin-management/${obj.id}`)}
    //       >
    //         <EyeFilled />
    //       </button>
    //       <button
    //         className="mx-2"
    //         onClick={() => history.push(`/edit-admin-management/${obj.id}`)}
    //       >
    //         <EditFilled />
    //       </button>
    //       <button className="mx-2" onClick={() => handleOpenModalDelete(obj)}>
    //         <DeleteFilled />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      return
    }

    let sortType = sorter.order
    sortType = sortType.replace("end", "")
    setParams({
      ...params,
      order: `${sorter.field},${sortType}`,
    });
  }
  const handleLimitChange = (event) => {
    if (listAdmin?.total_data < event.target.value) {
      setCurrentPage(1)
      setLimitPageSize(event.target.value)
      setParams({
        ...params,
        offset: 0,
        limit: event.target.value,
      });
      return
    }
    setLimitPageSize(event.target.value)
    setParams({
      ...params,
      limit: event.target.value,
    });
  }

  //define pagination
  let totalPage
  totalPage = listAdmin?.total_data
  if (listAdmin?.total_data < limitPageSize) {
    totalPage = 1
  }

  // if(listAdmin?.total_data > limitPageSize){
  //   if(listAdmin?.total_data%limitPageSize === 0){
  //     totalPage = listAdmin?.total_data/limitPageSize
  //     totalPage *= limitPageSize
  //   }else{
  //     let modLeft, evenPageNumber
  //     modLeft = listAdmin?.total_data%limitPageSize
  //     evenPageNumber = listAdmin?.total_data - modLeft
  //     totalPage = (evenPageNumber/limitPageSize)+1
  //     totalPage *= limitPageSize
  //   }
  // }

  let component = () => {
    return (
      <div className="admin-management">
        <AdminNav pageTitle="Admin Management" />

        <div className="admin-management__content">
          <div className="header">
            <div className="container-header">
              <Button onClick={() => history.push("/add-admin-management")}>
                + Tambah Admin
              </Button>
              <SearchInput
                placeholder="Cari Nama Admin"
                value={params.name}
                onChange={(e) =>
                  setParams({
                    ...params,
                    name: e.target.value,
                  })
                }
              /></div>
            <div className="container-header">
              <Select
                options={activeOptions}
                onChange={(e) => {
                  setParams({
                    ...params,
                    status: e.target.value
                  })
                }
                }
              />
              <Select
                options={limitOptions}
                onChange={handleLimitChange}
              />
              <Select
                options={sortOptions}
                onChange={(e) => {
                  setParams({
                    ...params,
                    order: e.target.value
                  })
                }
                }
              />
            </div>
          </div>

          <div className="admin-table-wrapper">
            <Table
              dataSource={listAdmin?.users}
              loading={loading}
              columns={columns}
              pagination={false}
              onChange={handleTableChange}
            />
          </div>

          <Pagination
            className="pagination-component"
            current={currentPage}
            defaultPageSize={limitPageSize}
            total={totalPage}
            onChange={(val) => handlePagination(val)}
          />

          {/* <Modal
              type="confirm"
              title={`Apakah Anda Yakin Ingin Menghapus Admin ${adminDelete.name}?`}
              buttonText="Hapus Admin"
              isModalActive={isConfirmModalActive}
              onModalClosed={() => handleCloseModalDelete()}
              onModalConfirmed={handleOrderRequest}
              isLoading={false}
            /> */}
        </div>
      </div>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default AdminManagement;
