import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import "antd/dist/antd.css";
import { unwrapResult } from "@reduxjs/toolkit";
import ImageUploading from "react-images-uploading";
import NoImage from "../../../assets/img/no-image-icon-23485.png";
import Template from "../../shared/Template";

import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";
import { Button, Card, FormGroup, Input, Dropdown, Modal } from "../../../components";

import { updateDealer, getDetailDealer } from "../dealer.api";
import { uploadFile, deleteFile } from "../../../services/fileService";
import { getListProvince } from "../../region/province.api";
import { getListCity } from "../../region/city.api";
import { getListRegency } from "../../region/regency.api";
import { getListDistrict } from "../../region/district.api";

import "../styles.scss";

export default function EditDealer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const accessToken = localStorage.getItem("accessToken")
  const decodedToken = jwtDecode(accessToken)
  
  const id = decodedToken.dealerId

  const [isPhotoUpdate, setIsPhotoUpdate] = useState(false)
  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false);
  const { regencyOptions } = useSelector((state) => state.regency);
  const { districtOptions } = useSelector((state) => state.district);
  const { provinceOptions } = useSelector((state)=> state.province);
  const { cityOptions } = useSelector((state)=> state.city)
  const { isUploading } = useSelector((state)=> state.upload)
  const { loading, isUpdating } = useSelector((state) => state.dealer);

  const handleProvinceChange = (value) => {
    formik.setValues({
      ...formik.values,
      village_id:"",
      district_id:"",
      city_id:"",
      province_id:value
    })
    dispatch(getListCity({id:null,province_id:value}))
    .catch((err)=>{
      alert(err)
    })
  };

  const handleCityChange = (value) => {
    formik.setValues({
      ...formik.values,
      village_id:"",
      district_id:"",
      city_id:value
    })
    dispatch(getListDistrict({id:null,city_id:value}))
    .catch((err)=>{
      alert(err)
    })
  };

  const handleDistrictChange = (value) => {
    formik.setValues({
      ...formik.values,
      village_id:"",
      district_id:value,
    })
    dispatch(getListRegency({id:null,district_id:value}))
    .catch((err)=>{
      alert(err)
    })
  };

  const handleVillageChange = (value) => {
    const regency = regencyOptions.find(regency=>regency.value === value)
    const {postcode} = regency
    formik.setValues({
      ...formik.values,
      lat:regency.lat,
      lon:regency.lng,
      postal_code:postcode,
      village_id:value
    })
  };

  const cancel = () => {
    history.push("/dealer-list/detail");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      photo_url: "",
      old_photo_url:"",
      file:[],
      pic_name: "",
      email: "",
      phone: "",
      address: "",
      province_id:0,
      city_id: 0,
      district_id: 0,
      village_id:0,
      postal_code: 0,
      lat: "",
      lon: "",
      is_active:false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      pic_name: Yup.string().required("PIC name is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      address: Yup.string().required("Address is required"),
      province_id: Yup.number().moreThan(0).required("Province is required"),
      city_id: Yup.number().moreThan(0).required("City is required"),
      district_id: Yup.number().moreThan(0).required("District is required"),
      village_id: Yup.number().moreThan(0).required("Village is required"),
      postal_code: Yup.string().required("Postal code is required"),
      lat: Yup.number().required("Latitude is required"),
      lon: Yup.number().required("Longitude is required"),
    }),
    onSubmit: (values) => {
      const {
        name,
        description,
        photo_url,
        old_photo_url,
        file,
        pic_name,
        email,
        phone,
        address,
        province_id,
        city_id,
        district_id,
        village_id,
        postal_code,
        lat,
        lon,
        is_active,
      } = values;

      const payload = {
        id,
        name,
        description,
        photo_url,
        pic_name,
        email,
        phone,
        address,
        province_id,
        city_id,
        district_id,
        village_id,
        postal_code,
        lat,
        lon,
        status:is_active? "active" : "inactive"
      };
      if(isPhotoUpdate){
        const payloadFile = {
          file: file[0].file,
          path: "brands",
          overwrite: false,
          previous_file: "",
        };
        dispatch(deleteFile({file_url:old_photo_url}))
        .catch((err) => {
          console.error(err, "Failed delete old dealer photo");
          alert("Failed delete old dealer photo", err);
        });
        dispatch(uploadFile(payloadFile))
        .then((response) => {
          payload.photo_url = response.payload.data.file_url;
          dispatch(updateDealer(payload))
            .then(unwrapResult)
            .then((response) => {
              console.info(response, "Success Update dealer");
              setIsSuccessModalActive(true);
            })
            .catch((err) => {
              console.error(err, "Failed Update dealer");
              alert("Update dealer was failed", err);
            });
        })
        .catch((err) => {
          console.error(err);
          alert("Failed Update a dealer", err);
        });
      }else{
        dispatch(updateDealer(payload))
        .then(unwrapResult)
        .then((response) => {
          console.info(response, "Success Update dealer");
          setIsSuccessModalActive(true);
        })
        .catch((err) => {
          console.error(err, "Failed Update dealer");
          alert("Update dealer was failed", err);
        });
      }
    },
  });

  useEffect(() => {
    dispatch(getDetailDealer(id))
    .unwrap()
    .then((res)=>{
      formik.setValues({
        ...formik.values,
        name: res.data.name,
        description: res.data.description,
        photo_url:res.data.photo_url,
        pic_name: res.data.pic_name,
        email: res.data.email,
        phone: res.data.phone,
        address: res.data.address,
        postal_code: res.data.postal_code,
        lat: res.data.lat,
        lon: res.data.lon,
        is_active: res.data.status === "active" ? true : false
      })
      dispatch(getListProvince({id:res.data.province_id}))
      .unwrap()
      .then(()=>{
        formik.setFieldValue("province_id",res.data.province_id)
      })
      .catch((err)=>{
        alert(err)
      })
      dispatch(getListCity({id:res.data.regency_id}))
      .unwrap()
      .then(()=>{
        formik.setFieldValue("city_id",res.data.regency_id)
      })
      .catch((err)=>{
        alert(err)
      })
      dispatch(getListDistrict({id:res.data.district_id}))
      .unwrap()
      .then(()=>{
        formik.setFieldValue("district_id",res.data.district_id)
      })
      .catch((err)=>{
        alert(err)
      })
      dispatch(getListRegency({id:res.data.village_id}))
      .unwrap()
      .then(()=>{
        formik.setFieldValue("village_id",res.data.village_id)
      })
      .catch((err)=>{
        alert(err)
      })
    })
    .catch((err)=>{
      alert(err)
    })
  }, [dispatch]);

  const { handleSubmit } = formik;
  let component =()=>{
    return (
      <LayoutStockV2 isNavAdmin={true} title="Edit Dealer">
        { loading ? (null)
        : (<section className="create-product__content">
            <form onSubmit={handleSubmit}>
              <Card>
                <Card.Content title="Informasi Dealer">
                  <div className="content__product-information">
                    <div className="form-size--half">
                      <FormGroup>
                        <Input
                          type="text"
                          id="name"
                          label="Nama Dealer"
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                        {formik.errors.name ? (
                          <div className="label-validation-error">{formik.errors.name}</div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          id="pic_name"
                          label="Nama PIC"
                          name="pic_name"
                          onChange={formik.handleChange}
                          value={formik.values.pic_name}
                        />
                        {formik.errors.pic_name ? (
                          <div className="label-validation-error">{formik.errors.pic_name}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="form-size--half">
                      <FormGroup>
                        <Input
                          type="text"
                          id="email"
                          label="Email"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                        {formik.errors.email ? (
                          <div className="label-validation-error">{formik.errors.email}</div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          id="phone"
                          label="No. Telepon"
                          name="phone"
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                        />
                        {formik.errors.phone ? (
                          <div className="label-validation-error">{formik.errors.phone}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="form-size--half">
                      <FormGroup>
                        <Input
                          type="text"
                          id="address"
                          label="Alamat"
                          name="address"
                          onChange={formik.handleChange}
                          value={formik.values.address}
                        />
                        {formik.errors.address ? (
                          <div className="label-validation-error">{formik.errors.address}</div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Dropdown
                          label="Provinsi"
                          placeholder="Pilih Provinsi"
                          items={provinceOptions}
                          onChange={handleProvinceChange}
                          selectedItem={formik.values.province_id}
                        />
                        {formik.errors.province_id ? (
                          <div className="label-validation-error">{formik.errors.province_id}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="form-size--half">
                      <FormGroup>
                        <Dropdown
                          label="Kabupaten/Kota"
                          placeholder="Pilih Kabupaten/Kota"
                          items={cityOptions}
                          onChange={handleCityChange}
                          selectedItem={formik.values.city_id}
                        />
                        {formik.errors.city_id ? (
                          <div className="label-validation-error">{formik.errors.city_id}</div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Dropdown
                          label="Kecamatan"
                          placeholder="Pilih Kecamatan"
                          items={districtOptions}
                          onChange={handleDistrictChange}
                          selectedItem={formik.values.district_id}
                        />
                        {formik.errors.district_id ? (
                          <div className="label-validation-error">{formik.errors.district_id}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="form-size--half">
                      <FormGroup>
                        <Dropdown
                          label="Kelurahan"
                          placeholder="Pilih Kelurahan"
                          items={regencyOptions}
                          onChange={handleVillageChange}
                          selectedItem={formik.values.village_id}
                        />
                        {formik.errors.village_id ? (
                          <div className="label-validation-error">{formik.errors.village_id}</div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          id="postal_code"
                          label="Kode POS"
                          name="postal_code"
                          onChange={formik.handleChange}
                          value={formik.values.postal_code}
                          disabled={true}
                        />
                        {formik.errors.postal_code ? (
                          <div className="label-validation-error">{formik.errors.postal_code}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="form-size--half">
                      <FormGroup>
                        <Input
                          type="number"
                          id="lat"
                          label="Latitude"
                          name="lat"
                          onChange={formik.handleChange}
                          value={formik.values.lat}
                          disabled={true}
                        />
                        {formik.errors.lat ? (
                          <div className="label-validation-error">{formik.errors.lat}</div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="number"
                          id="lon"
                          label="Longitude"
                          name="lon"
                          onChange={formik.handleChange}
                          value={formik.values.lon}
                          disabled={true}
                        />
                        {formik.errors.lon ? (
                          <div className="label-validation-error">{formik.errors.lon}</div>
                        ) : null}
                      </FormGroup>
                      {/* <FormGroup>
                        <Checkbox
                          checked={formik.values.is_active}
                          disabled={false}
                          onChange={(e) =>
                            formik.setFieldValue("is_active",e.target.checked)
                          }
                        ><i>Status</i></Checkbox>
                      </FormGroup> */}
                    </div>
                    <FormGroup>
                      <Input
                        type="textarea"
                        id="description"
                        label="Deskripsi"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                      />
                      {formik.errors.description ? (
                        <div className="label-validation-error">{formik.errors.description}</div>
                      ) : null}
                    </FormGroup>
                  </div>
                </Card.Content>
              </Card>
              <Card className="mt-6">
                <Card.Content title="Gambar Dealer">
                  <div>
                    <label className="label">Gambar</label>
                    <ImageUploading
                      // value={formik.values.file[0]}
                      onChange={(imageList, addUpdateIndex) => {
                        setIsPhotoUpdate(true)
                        const result = imageList[addUpdateIndex]
                        formik.setValues({
                          ...formik.values,
                          old_photo_url:formik.values.photo_url,
                          photo_url:result.data_url,
                          file:imageList,
                        }
                        )}}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div
                          className="upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center"
                          style={{ minHeight: 100, maxWidth: 200 }}
                        >
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {!formik.values.photo_url ? ("Click or Drop here") 
                            : (
                              <img key={1} src={formik.values.photo_url} alt="" width="100" onError={(e) => {
                                console.log(NoImage)
                                e.target.src = NoImage}}/>
                            )}
                          </button>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </Card.Content>
              </Card>

              <div className="content__actions">
                <Button variant="secondary" type="button" textVariant="big" onClick={() => cancel()}>
                  Batal
                </Button>
                <Button variant="big" type="submit">
                  {isUploading ? ("Mengupload..") : isUpdating ? ("Menyimpan..") : ("Simpan")}
                </Button>
              </div>
            </form>
          </section>
        )}
        <Modal
          type="success"
          title="Dealer Updated Successfully"
          isModalActive={isSuccessModalActive}
          onModalClosed={() => history.push("/dealer-list/detail")}
          isLoading={false}
        />
      </LayoutStockV2>
    );
  }
  return <Template noNavbar isSideNav title="Profile" component={component} />;
}