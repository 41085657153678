import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getListDealer } from "../dealer.api";
import { dataDealer, bodyCreate } from "../adminManagement.api";
import { Button, Card, AdminNav } from "../../../components";

import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";
import Wheel from "../../../assets/img/bengkel.png";

import "./styles.scss";
import Template from "../../shared/Template";

const DealerAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListDealer({}));
  }, [dispatch]);

  const { dataBodyCreate } = useSelector((state) => state.admin);
  const { listDealer } = useSelector((state) => state.dealer);

  const [objectCreate, setObjectCreate] = useState(dataBodyCreate);
  const [selectedDealer, setSelectedDealer] = useState({});

  const breadcrumbItems = [
    {
      title: "Admin Management",
      to: "/admin-management",
    },
    {
      title: "Konfir Dealer Admin",
      noIcon: "noIcon",
    },
  ];

  const handleBack = () => {
    history.push("/add-admin-management");
  };

  const handleSelectDealer = (value) => {
    setSelectedDealer(value);
    setObjectCreate({
      ...objectCreate,
      dealer_id: value.id,
    });
  };

  const handleKonfir = () => {
    dispatch(dataDealer(selectedDealer));
    dispatch(bodyCreate(objectCreate));
    history.push("/detail-dealer-admin-management");
  };
  let component = () => {
    return (
      <>
        <AdminNav breadcrumbItems={breadcrumbItems} />

        <LayoutStockV2>
          <Card.Content title="Pilih Dealer">
            <div className="mt-5 form-size--four">
              {listDealer.map((item, key) => (
                <div
                  key={key}
                  style={{
                    boxShadow:
                      selectedDealer.id === item.id
                        ? "0px 10px 10px rgba(0, 0, 0, 0.1)"
                        : "",
                    border:
                      selectedDealer.id === item.id ? "1px solid #E9522A" : "",
                  }}
                  onClick={() => handleSelectDealer(item)}
                  className="card"
                >
                  <img
                    src={item.photo_url === "" ? Wheel : item.photo_url}
                    alt="wheel"
                    className="img--large"
                  />
                  <p
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "24px",
                      color: "#1A2D47",
                    }}
                  >
                    {item.name}
                  </p>
                  <p>{item.address !== "" ? item.address : "-"}</p>
                </div>
              ))}
            </div>

            <div
              className="form-size--one"
              style={{ borderBottomWidth: 0, marginTop: "2.5em" }}
            >
              <Button variant="full" onClick={() => handleKonfir()}>
                <span style={{ color: "#fff" }}>Konfirmasi</span>
              </Button>
            </div>
            <div className="form-size--one" style={{ borderBottomWidth: 0 }}>
              <Button variant="white" onClick={() => handleBack()}>
                <span style={{ color: "#E9522A" }}>Kembali</span>
              </Button>
            </div>
          </Card.Content>
        </LayoutStockV2>
      </>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default DealerAdmin;
