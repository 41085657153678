import React from "react";
import classNames from "classnames";

import { Button, Input, Dropdown, SearchDropDown, SearchDropDown2 } from "..";
import ImageUploading from "react-images-uploading";

import "./styles.scss";

const HomepageModal = ({
  title,
  description,
  isModalActive,
  onModalClosed,
  onModalConfirmed,
  type,
  buttonColor,
  buttonText,
  isLoading,
  onChangeTitle,
  valueImageBanner,
  valueImage2,
  onChangeImage,
  onChangeSubTitle,
  onChangeDescription,
  bannerStatus,
  handleBannerStatusClick,
  selectedItem,
  onChangeVideo,

  // Hot Deals
  onChangeImageHotDeals,
  valueImageHotDeals,
  valueImage2HotDeals,
  onChangeTitleHotDeals,
  onChangeSubTitleHotDeals,
  onChangeDescriptionHotDeals,
  hotDealsStatus,
  handleHotDealsStatusClick,
  selectedItemHotDeals,
  onChangeVideoHotDeals,
  hotDealsProduk,
  onChangeHotDealsProduct,
  hotDealsPromo,
  onChangePromo,

  bannerlistBannerID,
  hotDealsListId,
}) => {
  const toggleModal = () => {
    if (!isLoading) {
      onModalClosed();
    }
  };

  return (
    <>
      {isModalActive && (
        <div
          className={classNames("modal", {
            "modal--confirm": type === "confirm",
            "modal--show": !isLoading,
          })}
        >
          <div
            style={
              type === "confirm" ? { backgroundColor: "#AF1A00" } : { backgroundColor: "#E9522A" }
            }
            className="modal__header"
          />
          <div className={type === "edit" ? "modal__edit" : "modal__body"}>
            <h1 style={type === "confirm" ? { color: "#AF1A00" } : { color: "#E9522A" }}>
              {type === "confirm" ? "PERHATIAN" : type === "edit" ? "EDIT BANNER" : "EDIT HOTDEALS"}
            </h1>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          {type === "edit" && (
            <>
              <div>
                <div className="form-size--half">
                  <Input
                    style={{ overflow: "auto" }}
                    type="text"
                    label="Banner Title"
                    id="banner_title"
                    onChange={onChangeTitle}
                    defaultValue={bannerlistBannerID?.title}
                  ></Input>
                  <Input
                    type="text"
                    label="Sub Title"
                    id="banner_subTitle"
                    onChange={onChangeSubTitle}
                    defaultValue={bannerlistBannerID?.subtitle}
                  ></Input>
                </div>
                <div className="form-size--half">
                  <Input
                    type="text"
                    label="Description"
                    id="banner_description"
                    onChange={onChangeDescription}
                    defaultValue={bannerlistBannerID?.description}
                  ></Input>
                  <Dropdown
                    placeholder="Select Status"
                    label="Banner Status"
                    items={bannerStatus}
                    onChange={handleBannerStatusClick}
                    selectedItem={selectedItem}
                  />
                </div>
                <div className="form-size--half">
                  <ImageUploading
                    value={valueImage2}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div
                        className="upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center"
                        style={{ minHeight: 100, maxWidth: 200 }}
                      >
                        <button
                          type="button"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          {valueImageBanner && "Click or Drop here"}

                          {valueImageBanner?.map((image, index) => (
                            <img key={index} src={image["data_url"]} alt="" width="100" />
                          ))}
                        </button>
                      </div>
                    )}
                  </ImageUploading>
                  <Input
                    type="text"
                    label="Link Video"
                    id="banner_linkvideo"
                    onChange={onChangeVideo}
                    defaultValue={bannerlistBannerID?.linkvideo}
                  ></Input>
                </div>
              </div>
              <div className="modal__actions">
                <Button
                  widthVariant="full"
                  textVariant="big"
                  variant="secondary"
                  onClick={onModalClosed}
                  disabled={isLoading}
                >
                  Batal
                </Button>
                <Button
                  colorVariant={buttonColor}
                  widthVariant="full"
                  textVariant="big"
                  onClick={onModalConfirmed}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {buttonText}
                </Button>
              </div>
            </>
          )}

          {type === "editHotDeals" && (
            <>
              <div>
                <div className="form-size--half">
                  <Input
                    type="text"
                    label="Hot Deals Title"
                    id="hotDeals_title"
                    onChange={onChangeTitleHotDeals}
                    defaultValue={hotDealsListId?.title}
                  ></Input>
                  <Input
                    type="text"
                    label="Sub Title"
                    id="hotDeals_subTitle"
                    onChange={onChangeSubTitleHotDeals}
                    defaultValue={hotDealsListId?.subtitle}
                  ></Input>
                </div>
                <div className="form-size--half">
                  <Input
                    type="text"
                    label="Description"
                    id="hotDeals_description"
                    onChange={onChangeDescriptionHotDeals}
                    defaultValue={hotDealsListId?.description}
                  ></Input>
                  <Dropdown
                    placeholder="Select Status"
                    label="Hot Deals Status"
                    items={hotDealsStatus}
                    onChange={handleHotDealsStatusClick}
                    selectedItem={selectedItemHotDeals}
                  />
                </div>
                <div className="form-size--half">
                  <SearchDropDown
                    type="text"
                    id="hotDeals_produk"
                    name="hotDeals_produk"
                    placeholder="Enter or Select Produk"
                    label="Hot Deals Produk"
                    itemsProduk={hotDealsProduk}
                    onChange={onChangeHotDealsProduct}
                    //  value={valueHotDealsProduct}
                    defaultValue2={hotDealsListId?.produk}
                  />
                  <SearchDropDown2
                    type="text"
                    id="hotDeals_promo"
                    name="hotDeals_promo"
                    placeholder="Enter or Select Promo"
                    label="Hot Deals Promo"
                    itemsPromo={hotDealsPromo}
                    onChange={onChangePromo}
                    // value={valuePromo}
                    defaultValue={hotDealsListId?.promo}
                  />
                </div>
                <div className="form-size--half">
                  <ImageUploading
                    value={valueImage2HotDeals}
                    onChange={onChangeImageHotDeals}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div
                        className="upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center"
                        style={{ minHeight: 100, maxWidth: 200 }}
                      >
                        <button
                          type="button"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          {valueImageHotDeals && "Click or Drop here"}

                          {valueImageHotDeals?.map((image, index) => (
                            <img key={index} src={image["data_url"]} alt="" width="100" />
                          ))}
                        </button>
                      </div>
                    )}
                  </ImageUploading>
                  <Input
                    type="text"
                    label="Link Video"
                    id="banner_linkvideo"
                    onChange={onChangeVideoHotDeals}
                    defaultValue={hotDealsListId?.linkvideo}
                  ></Input>
                </div>
              </div>
              <div className="modal__actions">
                <Button
                  widthVariant="full"
                  textVariant="big"
                  variant="secondary"
                  onClick={onModalClosed}
                  disabled={isLoading}
                >
                  Batal
                </Button>
                <Button
                  colorVariant={buttonColor}
                  widthVariant="full"
                  textVariant="big"
                  onClick={onModalConfirmed}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {buttonText}
                </Button>
              </div>
            </>
          )}

          {/* HotDeals */}
        </div>
      )}

      {isModalActive && <div className="modal-overlay" onClick={toggleModal} />}
    </>
  );
};

export default HomepageModal;
