import React from "react";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getDetailDealer } from "../dealer.api";

import {
  FormGroup,
  Card,
  AdminNav,
  DetailImage,
  Button,
} from "../../../components";
import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";

import { EditOutlined } from "@ant-design/icons";
import Template from "../../shared/Template";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "../styles.scss"

const DetailDealer = ({ tableName, isVisible, startDate, endDate }) => {
  const accessToken = localStorage.getItem("accessToken")
  const decodedToken = jwtDecode(accessToken)

  const { detailDealer, loading } = useSelector(state => state.dealer)
  const dispatch = useDispatch()

  const history = useHistory();
  // const breadcrumbItems = [
    
  // ];

  const handleEdit = (value) => {
    history.push("/dealer-list/edit");
  };

  useEffect(()=>{
    dispatch(getDetailDealer(decodedToken.dealerId))
  },[dispatch])

  let component = () => {
    return (
      <div>
        <AdminNav pageTitle="Detail Dealer"/>
        <LayoutStockV2>
        {
          loading ? (
          <Card.Content>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Card.Content>
          ) : (
            <Card.Content title="Info Dealer">
            <div>
              <Card className="mt-6">
                <Card.Content title="Detail Dealer">
                  <div className="content__product-information">
                    <div className="form-size--half">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Nama Dealer</Form.Label>
                        <div>
                          {detailDealer.name}
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Nama PIC</Form.Label>
                        <div>
                          {detailDealer.pic_name}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-size--half">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Email</Form.Label>
                        <div>
                          {detailDealer.email}
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>No. Telepon</Form.Label>
                        <div>
                          {detailDealer.phone}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-size--half">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Alamat</Form.Label>
                        <div>
                          {detailDealer.address}
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Provinsi</Form.Label>
                        <div>
                          {detailDealer.province_name}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-size--half">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Kabupaten/Kota</Form.Label>
                        <div>
                          {detailDealer.city_name}
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Kecamatan</Form.Label>
                        <div>
                          {detailDealer.district_name}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-size--half">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Kelurahan</Form.Label>
                        <div>
                          {detailDealer.village_name}
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Kode Pos</Form.Label>
                        <div>
                          {detailDealer.postal_code}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-size--half">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Latitude</Form.Label>
                        <div>
                          {detailDealer.lat}
                        </div>
                      </Form.Group>
                      <Form.Group>
                      <Form.Label style={{ fontWeight: 'bold' }}>Longitude</Form.Label>
                        <div>
                          {detailDealer.lon}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="form-size--half">
                    <Form.Group>
                      <Form.Label style={{ fontWeight: 'bold' }}>Deskripsi</Form.Label>
                      <div>
                        {detailDealer.description}
                      </div>
                    </Form.Group>
                    <FormGroup>
                      <DetailImage 
                        text="Photo" 
                        title={`${detailDealer.name}`}
                        srcImage={`${detailDealer.photo_url}`}
                        description={`${detailDealer.city_name} ${detailDealer.postal_code}`}
                        className="dealerImageSize"
                      />
                    </FormGroup>
                  </div>
                  {/* <div className="form-size--half">
                    <Form.Group>
                      <Form.Label style={{ fontWeight: 'bold' }}>Status</Form.Label>
                      <div>
                        <Badge variant={detailDealer.status == "active" ? "green" : "red"}>
                          {detailDealer.status}
                        </Badge>
                      </div>
                    </Form.Group>
                  </div> */}
                </Card.Content>
              </Card>
            </div>
            <div className="form-size--one" style={{ borderBottomWidth: 0, marginTop: "2.5em" }}>
              <Button variant="full" onClick={(value) => handleEdit(value)}>
                <EditOutlined className="me-2" />
                <span style={{ color: "#fff" }}>Edit</span>
              </Button>
            </div>
          </Card.Content>
          )
        }
        </LayoutStockV2>
      </div>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default DetailDealer;
