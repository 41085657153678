import { useState } from "react"

const useInputValidation = (validate,setValue,err)=>{
    const [valueIsValid, setValueIsValid] = useState(false)
    const [valueIsTouched, setValueIsTouched] = useState(false)


    let errorValue = !valueIsValid && valueIsTouched
    let errMessageValue = ""
    if(errorValue){
        errMessageValue = err
    }

    const valueChangeHandler = (event) => {
        setValue(event.target.value)
        if(validate(event.target.value)){
            setValueIsValid(true)
            return
        }
        setValueIsValid(false)
    };

    const valueBlurHandler = (event) => {
        setValueIsTouched(true)
        if(validate(event.target.value)){
            setValueIsValid(true)
            return
        }
        setValueIsValid(false)
    };

    return {
        valueIsValid,
        errorValue,
        errMessageValue,
        onChange: valueChangeHandler,
        onBlur: valueBlurHandler
    }
}

const useDropdownValidation = (validate,setValue,err)=>{
    const [valueIsValid, setValueIsValid] = useState(false)
    const [valueIsTouched, setValueIsTouched] = useState(false)


    let errorValue = !valueIsValid && valueIsTouched
    let errMessageValue = ""
    if(errorValue){
        errMessageValue = err
    }

    const valueChangeHandler = (value) => {
        setValue(value)
        if(validate(value)){
            setValueIsValid(true)
            return
        }
        setValueIsValid(false)
    };

    const valueBlurHandler = (value) => {
        setValueIsTouched(true)
        if(validate(value)){
            setValueIsValid(true)
            return
        }
        setValueIsValid(false)
    };

    return {
        valueIsValid,
        errorValue,
        errMessageValue,
        onChange: valueChangeHandler,
        onBlur: valueBlurHandler
    }
}

export {useInputValidation, useDropdownValidation}