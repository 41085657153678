import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { Link } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
// import ImageUploading from "react-images-uploading";
import Template from "../../shared/Template";
import { getDetailAdmin, putAdmin } from "../adminManagement.api";

// import actions from "../../../../../../store/reducers/transaction/actions";
import {
  FormGroup,
  Input,
  Button,
  Modal,
  Card,
  AdminNav,
  Checkbox,
} from "../../../components";
import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";
import { useInputValidation } from "../../../hooks/use-formValidation";
import isNumberOnlyUtils from "../../../utils/isNumberObluUtils";
import validateEmailUtils from "../../../utils/validateEmailUtils";
// import Wheel from "../../../assets/img/bengkel.png";
// import { Button } from "antd";
import "./styles.scss";

const EditDetailAdmin = () => {
  // const [status, setStatus] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // const [linkphoto, setLinkPhoto] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetailAdmin(id)).then((res) => {
      // const resData = res.payload.data;

      // if (resData.type === "dealer") {
      //   dispatch(getDetailDealer(resData.dealer_id));
      // }
    });
  }, [dispatch, id]);

  const { detailAdmin } = useSelector((state) => state.admin);
  // const adminStatus = [
  //   { label: "(Status Admin) 1", value: "active" },
  //   { label: "(Status Admin) 2", value: "inactive" },
  // ];

  const [objectCreate, setObjectCreate] = useState({
    id: id,
    name: detailAdmin.name,
    email: detailAdmin.email,
    type: detailAdmin.type,
    nik: detailAdmin.nik,
    is_active: detailAdmin.is_active,
    phone_number: detailAdmin.phone_number,
    dealer_id: detailAdmin.dealer_id,
  });
  // const adminStatus = [{ label: "Admin Dealer", value: "dealer" }];

  const{
    // valueIsValid:nikIsValid,
    errorValue:errorNik,
    errMessageValue:errMessageNik,
    onChange: nikChangeHandler,
    onBlur: nikBlurHandler
  } = useInputValidation(
    (value)=>{if(value.length === 16 && isNumberOnlyUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, nik: value })},
    "Nik harus 16 digit angka"
  )

  const{
    // valueIsValid:nameIsValid,
    errorValue:errorName,
    errMessageValue:errMessageName,
    onChange: nameChangeHandler,
    onBlur: nameBlurHandler
  } = useInputValidation(
    (value)=>{if(value.length > 0)return true},
    (value)=>{setObjectCreate({ ...objectCreate, name: value })},
    "Nama tidak boleh kosong"
  )

  const{
    // valueIsValid:emailIsValid,
    errorValue:errorEmail,
    errMessageValue:errMessageEmail,
    onChange: emailChangeHandler,
    onBlur: emailBlurHandler
  } = useInputValidation(
    (value)=>{if(validateEmailUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, email: value })},
    "Email tidak valid"
  )
  
  const{
    // valueIsValid:phoneNumberIsValid,
    errorValue:errorPhoneNumber,
    errMessageValue:errMessagePhoneNumber,
    onChange: phoneNumberChangeHandler,
    onBlur: phoneNumberBlurHandler
  } = useInputValidation(
    (value)=>{if(isNumberOnlyUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, phone_number: value })},
    "Nomer telepon hanya angka"
  )

  const breadcrumbItems = [
    {
      title: "Detail Admin Management",
      to: `/detail-admin-management/${id}`,
    },
    {
      title: "Edit Admin",
      noIcon: "noIcon",
    },
  ];

  // checking if all input is valid
  // let formInvalid = true
  // if (nikIsValid && emailIsValid && 
  //   phoneNumberIsValid && nameIsValid){
  //       formInvalid = false
  // }
  // if (objectCreate.nik.length === 16 && isNumberOnlyUtils(objectCreate.nik) &&
  //     validateEmailUtils(objectCreate.email) && 
  //     isNumberOnlyUtils(objectCreate.phone_number) && 
  //     objectCreate.name.length > 0){
  //       formInvalid = false
  // }


  const handleUpdate = () => {
    if (typeof objectCreate.nik === "undefined"){
      setObjectCreate(
        {...objectCreate,
          name: detailAdmin.name,
          email: detailAdmin.email,
          type: detailAdmin.type,
          nik: detailAdmin.nik,
          is_active: detailAdmin.is_active,
          phone_number: detailAdmin.phone_number,
          dealer_id: detailAdmin.dealer_id,
        })
      setIsModalActive(true);
      return
    }
    if (typeof objectCreate.name === "undefined"){
      setObjectCreate(
        {...objectCreate,
          name: detailAdmin.name,
          email: detailAdmin.email,
          type: detailAdmin.type,
          nik: detailAdmin.nik,
          is_active: detailAdmin.is_active,
          phone_number: detailAdmin.phone_number,
          dealer_id: detailAdmin.dealer_id,
        })
      setIsModalActive(true);
      return
    }
    if (typeof objectCreate.email === "undefined"){
      setObjectCreate(
        {...objectCreate,
          name: detailAdmin.name,
          email: detailAdmin.email,
          type: detailAdmin.type,
          nik: detailAdmin.nik,
          is_active: detailAdmin.is_active,
          phone_number: detailAdmin.phone_number,
          dealer_id: detailAdmin.dealer_id,
        })
      setIsModalActive(true);
      return
    }
    if (typeof objectCreate.phone_number === "undefined"){
      setObjectCreate(
        {...objectCreate,
          name: detailAdmin.name,
          email: detailAdmin.email,
          type: detailAdmin.type,
          nik: detailAdmin.nik,
          is_active: detailAdmin.is_active,
          phone_number: detailAdmin.phone_number,
          dealer_id: detailAdmin.dealer_id,
        })
      setIsModalActive(true);
      return
    }

    if (errorEmail || errorName || errMessagePhoneNumber || errMessageNik){
      setIsModalActive(true);
      return
    }

    // dispatch(bodyCreate(objectCreate));
    // previous
    // if (objectCreate.type === "admin") {
    //   history.push("/konfir-admin-management");
    // } else {
    //   history.push("/dealer-admin-management");
    // }
    dispatch(putAdmin(objectCreate)).then(() => {
      setIsSuccess(true)
      setIsModalActive(true);
    });
  };

  const handleBack = (value) => {
    history.push(`/detail-admin-management/${value}`);
  };

  let component = () => {
    return (
      <div>
        <AdminNav breadcrumbItems={breadcrumbItems} />

        <LayoutStockV2 style={{ padding: 20 }}>
          <Card.Content title="Informasi Admin">
            <div>
              <FormGroup>
                <Input
                  type="text"
                  label="NIK"
                  value={objectCreate.nik}
                  onChange={nikChangeHandler}
                  onBlur={nikBlurHandler}
                />
                {errorNik ? <label style={{color:"#ef4444"}}>{errMessageNik}</label> : null}
              </FormGroup>
            </div>

            <div className="mt-4">
              <FormGroup>
                <Input
                  type="text"
                  label="Nama Admin"
                  value={objectCreate.name}
                  onChange={nameChangeHandler}
                  onBlur={nameBlurHandler}
                />
                {errorName ? <label style={{color:"#ef4444"}}>{errMessageName}</label> : null}
              </FormGroup>
            </div>

            <div className="form-size--half mt-4">
              <FormGroup>
                <Input
                  type="text"
                  label="Email Admin"
                  value={objectCreate.email}
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                />
                {errorEmail ? <label style={{color:"#ef4444"}}>{errMessageEmail}</label> : null}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  label="No. Telepon"
                  value={objectCreate.phone_number}
                  onChange={phoneNumberChangeHandler}
                  onBlur={phoneNumberBlurHandler}
                />
                {errorPhoneNumber ? <label style={{color:"#ef4444"}}>{errMessagePhoneNumber}</label> : null}
              </FormGroup>
              <FormGroup>
                <Checkbox
                  checked={objectCreate.is_active}
                  disabled={false}
                  onChange={(e) =>
                    setObjectCreate({
                          ...objectCreate,
                          is_active: e.target.checked,
                        })
                  }
                ><i>Status</i></Checkbox>
              </FormGroup>
            </div>

            <div
              className="form-size--one"
              style={{ borderBottomWidth: 0, marginTop: "2.5em" }}
            >
              <Button onClick={() => handleUpdate()} variant="full">
                <span style={{ color: "#fff" }}>Perbaharui</span>
              </Button>
              <Button onClick={() => handleBack(id)} variant="white">
                <span style={{ color: "#E9522A" }}>Kembali</span>
              </Button>
            </div>
          </Card.Content>
        </LayoutStockV2>
        <Modal
          type={isSuccess ? "success" : "confirm"}
          title={isSuccess ? "Berhasil Memperbaharui dealer" : "Gagal memperbaharui dealer"}
          isModalActive={isModalActive}
          onModalClosed={() => history.push(`/detail-admin-management/${id}`)}
          onModalConfirmed={() => {
              history.push(`/edit-admin-management/${id}`)
              setIsModalActive(false);
            }
          }
          isLoading={false}
          buttonText="Ok"
        />
      </div>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default EditDetailAdmin;
