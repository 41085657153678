import { createSlice } from "@reduxjs/toolkit";
import { getListDealer, getDetailDealer } from "./dealer.api";

const dealerSlice = createSlice({
  name: "dealer",
  initialState: {
    loading: false,
    listDealer: [],
    detailDealer: {},
  },
  extraReducers: {
    [getListDealer.pending]: (state, action) => {
      state.loading = true;
    },
    [getListDealer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listDealer = payload.data.dealers;
    },
    [getListDealer.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDetailDealer.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailDealer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.detailDealer = payload.data;
    },
    [getDetailDealer.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default dealerSlice.reducer;
