import React from 'react'
import "./styles.scss";

function SearchDropDown({label, id, onChange,placeholder, value, onBlur, itemsProduk, defaultValue2}) {

  return (
    <div className="input-wrapper">
        <label>{label}</label>
        <input
              defaultValue={defaultValue2}
              // value={value}
              list="product"
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              type="text"
              id={id}
            />
          <datalist id="product">
          {
             itemsProduk?.map((item, key) =>
                 <option key={key} value={item.value} />
              )
            }
          </datalist>
    </div>
  )
}
function SearchDropDown2({label, id, onChange,placeholder, value, onBlur, itemsPromo, defaultValue}) {

  return (
    <div className="input-wrapper">
        <label>{label}</label>
        <input
              defaultValue={defaultValue}
              // value={value}
              list="balanar"
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              type="text"
              id={id}
            />
          <datalist id="balanar">
          {
             itemsPromo?.map((item, key) =>
                 <option key={key} value={item.value} />
              )
            }
          </datalist>
    </div>
  )
}

export  {SearchDropDown, SearchDropDown2}