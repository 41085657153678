import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Pagination, SearchInput, Table, Select } from "../../../components";
import { listAddOns } from "../addOns.api";
import { Badge } from "react-bootstrap";

import '../styles.scss';

const ComponentsTableAddOns = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [limitPageSize, setLimitPageSize] = useState(5);

  const [params, setParams] = useState({
    order: 'created_at,desc',
    offset: 0,
    limit: limitPageSize
  });

  const sortOptions = [
    { label: "Terbaru", value: "created_at,desc" },
    { label: "Terlama", value: "created_at,asc" }
  ];

  const activeOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" }
  ];

  const limitOptions = [
    { label: "5 Entri", value: 5 },
    { label: "10 Entri", value: 10 },
    { label: "25 Entri", value: 25 },
    { label: "50 Entri", value: 50 },
  ];

  const [dataAddOns, setDataOns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(listAddOns(params)).then((res) => {
      const resData = res.payload.data
      setDataOns(resData);
    })
  }, [dispatch, params])

  // const { listAddOns, loading } = useSelector((state) => state.dataAddOns );
  console.log(dataAddOns)

  const handleLimitChange = (event) => {
    if (listAddOns?.total_data < event.target.value) {
      setCurrentPage(1)
      setLimitPageSize(event.target.value)
      setParams({
        ...params,
        offset: 0,
        limit: event.target.value,
      });
      return
    }
    setLimitPageSize(event.target.value)
    setParams({
      ...params,
      limit: event.target.value,
    });
  }

  return (
    <Table>
      <Table.Nav>
        <div>
          <Button onClick={() => history.push("/add-ons-management/create")}>
            + Tambahan Add Ons
          </Button>

          <SearchInput
            placeholder="Cari Add Ons"
            value={params.name}
            onChange={(e) =>
              setParams({
                ...params,
                name: e.target.value,
              })
            }
          />
        </div>
        <div>
        <Select
            options={activeOptions}
            onChange={(e) => {
              setParams({
                ...params,
                status: e.target.value
              })
            }}
          />
          <Select
            options={limitOptions}
            onChange={handleLimitChange}
          />
          <Select
            options={sortOptions}
            style={{marginLeft: '10px'}}
            onChange={(e) => {
              setParams({
                ...params,
                order: e.target.value
              })
            }}
          />
        </div>

      </Table.Nav>

      <Table.Main>
        <Table.Head>
          <th>No.</th>
          <th>Nama Add Ons</th>
          <th>Harga</th>
          <th>Status</th>
          <th></th>
        </Table.Head>

        <Table.Body>
          {
            dataAddOns?.addons?.map((itemAddOns, key) => (
              <tr>
                <td>{key + 1}</td>
                <td>{itemAddOns.name}</td>
                <td><span >RP. </span>{parseFloat(itemAddOns.price).toLocaleString('id')}</td>
                <td>
                  <Badge
                    style={{
                      textTransform: "capitalize",
                      width: "100px",
                      fontSize: "12px",
                    }}
                    pill
                    bg={itemAddOns.status === "active" ?"success" :  "danger"}
                    text="light"
                  >
                    {itemAddOns.status}
                  </Badge>
                  </td>
                <td>
                  <div className="btn-detail" onClick={() => history.push(`/add-ons-management/detail/${itemAddOns.id}`)}>Lihat detail</div>
                </td>
              </tr>
            ))
          }
        </Table.Body>
      </Table.Main>

      <Pagination
        currentPage={currentPage}
        totalPages={dataAddOns?.total_page || 0}
        onPageChange={(page) => {
          setCurrentPage(page);

          setParams({
            ...params,
            offset: (page - 1) * params.limit,
          });
        }}
      />
    </Table>
  );
};

export default ComponentsTableAddOns;