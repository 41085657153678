import { createSlice } from '@reduxjs/toolkit';
import { getListAdmin, getDetailAdmin, bodyCreate, dataDealer } from './adminManagement.api';

const adminManagementSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    listAdmin: [],
    dataBodyCreate: {},
    dataDealer: {},
    detailAdmin: {}
  },
  extraReducers: {
    [getListAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [getListAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listAdmin = payload.data
    },
    [getListAdmin.rejected]: (state, action) => {
      state.loading = false
    },
    [getDetailAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.detailAdmin = payload.data
    },
    [getDetailAdmin.rejected]: (state, action) => {
      state.loading = false
    },
    [bodyCreate.fulfilled]: (state, { payload }) => {
      state.dataBodyCreate = payload
    },
    [dataDealer.fulfilled]: (state, { payload }) => {
      state.dataDealer = payload
    },
  }
});

export default adminManagementSlice.reducer;