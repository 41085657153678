import { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { Button, Card, FormGroup, Input, Modal } from ".."
import "./styles.scss"
import { X } from "react-feather"
import { useDispatch } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { updateTransactionInfo } from "pages/TransactionDetail/transaction-detail.api"

const InstallationModal = ({ data, disabled = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [isModalActive, setIsModalActive] = useState(false)
  // const [isSuccessModalActive, setIsSuccessModalActive] = useState(false)

  const toggleModal = () => {
    setIsModalActive(!isModalActive)
  }

  const formik = useFormik({
    initialValues: {
      time_installation: moment().format("YYYY-MM-DD"),
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      time_installation: Yup.date().required("Installation time is required"),
    }),
    onSubmit: (values) => {
      const { time_installation } = values
      const formattedTimeInstallation = moment(time_installation).format(
        "YYYY-MM-DD HH:mm:ss"
      )
      const payload = {
        time_installation: formattedTimeInstallation,
        dealer_id: data.dealer_id,
        order_id: data.order_address.order_id,
      }

      dispatch(updateTransactionInfo(payload))
        .then(unwrapResult)
        .then((response) => {
          // console.info(response, "Success Update installation")
          setIsModalActive(false)
          history.go(0)
        })
        .catch((err) => {
          console.error(err, "Failed Update dealer")
          alert("Update installation was failed", err)
        })
    },
  })

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      time_installation: moment(
        data.dealer_infromation?.time_instalation
      ).format("YYYY-MM-DD"),
    })
  }, [])

  const { handleSubmit } = formik

  return (
    <>
      <p className='text-primary' onClick={() => setIsModalActive(true)}>
        Edit
      </p>

      {isModalActive && (
        <Card className='general-modal'>
          <X
            className='general-modal__close'
            onClick={() => setIsModalActive(false)}
          />
          <Card.Content className='general-modal__content' title='Pemasangan'>
            <form className='content-form' onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  type='date'
                  id='time_installation'
                  label='Tanggal Pemasangan'
                  name='time_installation'
                  onChange={formik.handleChange}
                  value={formik.values.time_installation}
                />
                {formik.errors.time_installation ? (
                  <div className='label-validation-error'>
                    {formik.errors.time_installation}
                  </div>
                ) : null}
              </FormGroup>

              <Button
                type='submit'
                variant={Object.keys(formik.errors).length ? "grey" : "primary"}
                disabled={Object.keys(formik.errors).length > 0}
              >
                Kirim
              </Button>
            </form>
          </Card.Content>
        </Card>
      )}

      {isModalActive && <div className='modal-overlay' onClick={toggleModal} />}
      {/* <Modal
        type='success'
        title='Installation Updated Successfully'
        isModalActive={isSuccessModalActive}
        isLoading={false}
        onModalClosed={() =>
          history.push(`/transaction-management/${data.order_address.order_id}`)
        }
      /> */}
    </>
  )
}

export default InstallationModal
