import "./styles/App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/ForgotPassword/index"
import SetPassword from "./pages/ForgotPassword/SetPassword/index"
// import Transaction from "./pages/transaction/Transaction";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import DetailTransaction from "./pages/transaction/components/DetailTransaction";
import "antd/dist/antd.css";
import JwtDecode from "jwt-decode";
import isTokenExpiredUtils from "./utils/isTokenExpiredUtils";
import Swal from "sweetalert2";
import { postRefreshToken } from "./pages/login/refreshToken.api";
import { useDispatch } from "react-redux";
import { storeToken } from "./pages/accesToken.api";

import AdminManagement from "./pages/AdminManagement/index";
import AddAdminManagement from "./pages/AdminManagement/AddAdminManagement/index";
import KonfirAdminManagement from "./pages/AdminManagement/AddAdminManagement/KonfirmasiAdmin";
import DealerAdminManagement from "./pages/AdminManagement/AddAdminManagement/DealerAdmin";
import DetailDealerAdminManagement from "./pages/AdminManagement/AddAdminManagement/DetailDealer";
import DetailAdmin from "./pages/AdminManagement/DetailAdmin/index.jsx";
import EditDetailAdmin from "./pages/AdminManagement/DetailAdmin/EditDetailAdmin";

// Transaction
import TransactionManagement from "./pages/TransactionManagementV2/index";
import TransactionDetail from "./pages/TransactionDetail/v2/index";

// Dealer Management
import DealerManagement from "./pages/DealerManagement/DealerList";
import AddDealerManagement from "./pages/DealerManagement/DealerForm";
import DetailDealerManagement from "./pages/DealerManagement/DetailDealer/index";
import EditDealerManagement from "./pages/DealerManagement/DetailDealer/EditDealer";


// Add Ons Management
import AddOnsList from "./pages/AddOnsManagement";
import AddOnsForm from "./pages/AddOnsManagement/AddOnsForm";
import DetailAddOns from "./pages/AddOnsManagement/Detail";

// Notification Center
import NotificationCenter from "./pages/NotificationCenter";
import NotificationCenterDetail from "./pages/NotificationCenterDetail";

const PrivatRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const isAuth = localStorage.getItem("auth");
  const isToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const history = useHistory();
  try {
    let decodedAccessToken = JwtDecode(isToken)
    let decodedRefreshToken = JwtDecode(refreshToken)
    if(decodedAccessToken.dealerId === 0){
      localStorage.clear()
      history.push("/")
    }
    if(isTokenExpiredUtils(decodedAccessToken.exp)){
      if (isTokenExpiredUtils(decodedRefreshToken.exp)){
        Swal.fire({
          title:'Session Expired',
          text:'Your loggin session is expired will redirect to login page',
          timer:2000,
        }).then(()=>{
          localStorage.clear()
          history.push("/")
        })
      }else{
        const body = {
          refresh_token:refreshToken,
        }
        dispatch(postRefreshToken(body))
        .unwrap()
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("auth", "true");
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("accessToken", res.data.access_token);
            localStorage.setItem("refreshToken", res.data.refresh_token)
          }
          if (res.code !== 200) {
            Swal.fire("Error", `${res.data.message || "Login failed!"}`, "error");
          }
        });
      }
    }
    const tokenPayload = {
      dealer_id: decodedAccessToken.dealerId
    }
    dispatch(storeToken(tokenPayload))
  } catch (error) {
    history.push("/")
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth && isToken ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                // from: props.location
              },
            }}
          />
        )
      }
    />
  );
};

const UnPrivatRoute = ({ component: Component, ...rest }) => {
  const isAuth = localStorage.getItem("auth");
  const isToken = localStorage.getItem("accessToken");
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth && !isToken ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                // from: props.location
              },
            }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <Router>
      <Switch>
        <UnPrivatRoute exact path="/" component={Login} />
        <UnPrivatRoute exact path="/lupa-password" component={ForgotPassword} />
        <UnPrivatRoute exact path="/reset" component={SetPassword} />
        <PrivatRoute exact path="/dashboard" component={Dashboard} />
        {/* <PrivatRoute exact path="/transaction" component={Transaction} /> */}
        <PrivatRoute exact path="/profile" component={Profile} />
        <PrivatRoute
          exact
          path="/transaction/:idTransaction"
          component={DetailTransaction}
        />
        {/* Admin Management */}
        <PrivatRoute
          exact
          path="/admin-management"
          component={AdminManagement}
        />
        <PrivatRoute
          exact
          path="/add-admin-management"
          component={AddAdminManagement}
        />
        <PrivatRoute
          exact
          path="/konfir-admin-management"
          component={KonfirAdminManagement}
        />
        <PrivatRoute
          exact
          path="/dealer-admin-management"
          component={DealerAdminManagement}
        />
        <PrivatRoute
          exact
          path="/detail-dealer-admin-management"
          component={DetailDealerAdminManagement}
        />
        <PrivatRoute
          exact
          path="/detail-admin-management/:id"
          component={DetailAdmin}
        />
        <PrivatRoute
          exact
          path="/edit-admin-management/:id"
          component={EditDetailAdmin}
        />
        {/* Transaction */}
        <PrivatRoute
          exact
          path="/transaction-management"
          component={TransactionManagement}
        />
        <PrivatRoute
          exact
          path="/transaction-management/:id"
          component={TransactionDetail}
        />

        {/* Dealer Management */}
        <PrivatRoute exact path="/dealer-list" component={DealerManagement} />
        <PrivatRoute
          exact
          path="/dealer-list/form"
          component={AddDealerManagement}
        />
        <PrivatRoute
          exact
          path="/dealer-list/detail"
          component={DetailDealerManagement}
        />
        <PrivatRoute
          exact
          path="/dealer-list/edit"
          component={EditDealerManagement}
        />
        <PrivatRoute
          exact
          path="/add-ons-management"
          component={AddOnsList}
        />
        <PrivatRoute
          exact
          path="/add-ons-management/create"
          component={AddOnsForm}
        />
        <PrivatRoute
          exact
          path="/add-ons-management/detail/:idAddOns"
          component={DetailAddOns}
        />
        <PrivatRoute
          exact
          path="/add-ons-management/update/:idAddOns"
          component={AddOnsForm}
        />
        <PrivatRoute
          exact
          path="/notification-center"
          component={NotificationCenter}
        />
        <PrivatRoute
          exact
          path="/notification-center/detail/:id"
          component={NotificationCenterDetail}
        />
        <Route
          exact
          path="*"
          component={() => (
            <div className="fof">
              <h2>
                <b>404</b> | Page not found..
              </h2>
            </div>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
