import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import Skeleton from "react-loading-skeleton";

import { getListDealer, deleteDealer } from "../dealer.api";

import {
  Modal,
  Button,
  Table,
  SearchInput,
  Select,
  Pagination,
  Badge,
} from "../../../components";
import { PaperClipOutlined, UserOutlined } from "@ant-design/icons";

const DealerTable = () => {
  const limitOptions = [
    { label: "5 Entri", value: 5 },
    { label: "10 Entri", value: 10 },
    { label: "25 Entri", value: 25 },
    { label: "50 Entri", value: 50 },
  ];
  const sortOptions = [
    { label: "Terbaru", value: "D" },
    { label: "Terlama", value: "A" },
  ];
  const history = useHistory();
  const dispatch = useDispatch();

  const { listDealer, loading } = useSelector((state) => state.dealer);

  const [isConfirmModalActive, setIsConfirmModalActive] = useState(false);
  const [selectedDeleteDealer, setSelectedDeleteDealer] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [limit, setLimit] = useState(5);
  const [isAlertModalActive, setIsAlertModalActive] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleOrderRequest = () => {
    dispatch(deleteDealer(selectedDeleteDealer))
      .then(unwrapResult)
      .then(() => {
        setIsAlertModalActive(true);
      })
      .catch((err) => {
        console.error(err, "Failed delete dealer");
        alert(err.message);
      });
    handleCloseModalDelete();
    dispatch(getListDealer({ limit, currentPage, sort }));
  };

  const handleCloseModalDelete = () => {
    setIsConfirmModalActive(false);
    setSelectedDeleteDealer({});
  };

  const handleOpenModalDelete = (obj) => {
    setSelectedDeleteDealer(obj);
    setIsConfirmModalActive(true);
  };

  const handleSearchKeyPress = (e) => {
    if (e.charCode === 13) {
      dispatch(getListDealer({ limit, currentPage, sort, searchKeyword }));
    }
  };

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);

    if (e.target.value === "") {
      dispatch(getListDealer({ limit, currentPage, sort }));
    }
  };

  useEffect(() => {
    dispatch(getListDealer({ limit, currentPage, sort }));
  }, [limit, currentPage, sort]);

  return (
    <>
      <Table>
        <Table.Nav>
          <div>
            <Button onClick={() => history.push("/dealer-list/form")}>
              + Create Dealer
            </Button>
            <SearchInput
              placeholder="Search Dealer Name"
              value={searchKeyword}
              onChange={handleSearchChange}
              onKeyPress={handleSearchKeyPress}
            />
          </div>

          <div>
            <Select
              options={sortOptions}
              className="mr-4"
              onChange={(e) => setSort(e.target.value)}
            />
            <Select
              options={limitOptions}
              onChange={(e) => setLimit(e.target.value)}
            />
          </div>
        </Table.Nav>

        <Table.Main>
          <Table.Head>
            <th>Dealer ID</th>
            <th>Dealer Name</th>
            <th>PIC Name</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </Table.Head>
          <Table.Body>
            {loading ? (
              <>
                <tr className="table-skeleton-container">
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                </tr>
                <tr className="table-skeleton-container">
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                </tr>
                <tr className="table-skeleton-container">
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                </tr>
                <tr className="table-skeleton-container">
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                </tr>
                <tr className="table-skeleton-container">
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                  <td>
                    <Skeleton height={20} />
                  </td>
                </tr>
              </>
            ) : listDealer.length > 0 ? (
              listDealer.map((dealer, i) => {
                return (
                  <tr key={i}>
                    <td className="text-primary">
                      <Link to={`/dealer/${dealer.id}`}>{dealer.id}</Link>
                    </td>
                    <td>{dealer.name}</td>
                    <td>{dealer.pic_name}</td>
                    <td>{dealer.email}</td>
                    <td>
                      <Badge
                        variant={dealer.status == "active" ? "green" : "red"}
                      >
                        {dealer.status}
                      </Badge>
                    </td>
                    <td>
                      <Link
                        to="/dealer-list/detail"
                        className="text-primary dealer-detail "
                      >
                        <div className="dealer-detail__icon">
                          <PaperClipOutlined />
                        </div>
                        Lihat Detail
                      </Link>
                    </td>
                    <td>
                      <Button
                        variant="delete-table"
                        onClick={() => {
                          handleOpenModalDelete(dealer);
                        }}
                      >
                        Hapus
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <p>No Data Found</p>
            )}
          </Table.Body>
        </Table.Main>

        {loading ? (
          <Skeleton />
        ) : (
          <Pagination
            currentPage={currentPage}
            totalPages={8}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}

        <Modal
          title="Dealer Berhasil Dihapus"
          onModalClosed={() => setIsAlertModalActive(false)}
          isModalActive={isAlertModalActive}
        />
        <Modal
          type="confirm"
          title={`Apakah Anda Yakin Ingin Menghapus Dealer ${selectedDeleteDealer.name}?`}
          buttonText="Hapus Dealer"
          isModalActive={isConfirmModalActive}
          onModalClosed={() => handleCloseModalDelete()}
          onModalConfirmed={handleOrderRequest}
          isLoading={false}
        />
      </Table>
    </>
  );
};

export default DealerTable;
