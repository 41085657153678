import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LogoMain from "../../assets/img/logo.svg";
import { Button, Input, FormGroup, Modal } from "../../components";
import Swal from "sweetalert2";

import { forgotPassword } from "./forgotpass.api";
import { unwrapResult } from "@reduxjs/toolkit";
import "./styles.scss"
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector((state) => state.forgot);
  const [isModalActive, setIsModalActive] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit: (values) => {
      dispatch(forgotPassword(values))
        .then(unwrapResult)
        .then(() => {
          setIsModalActive(true);
        })
        .catch((err) => {
          Swal.fire("Error", `${err.message || "Login failed!"}`, "error");
        });
    },
  });

  return (
    <main className="login">
      <form className="login__form" onSubmit={formik.handleSubmit}>
        <img
          src={LogoMain}
          alt="logo-main"
          className="login__logo"
          onClick={() => history.push("/")}
        />
        {/* <div className="label-validation-error" style={{ marginBottom: "1rem" }}>
            {error}
          </div> */}

        <h2 style={{width:"100%"}}>Lupa Password</h2>
        <p>
          Kami akan kirimkan email verifikasi untuk pengaturan ulang kata sandi
          akun ke alamat email anda.
        </p>

        <FormGroup>
          <Input
            label="Email"
            type="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="label-validation-error">{formik.errors.email}</div>
          ) : null}
        </FormGroup>

        <Button
          type="submit"
          disabled={!(formik.isValid && formik.dirty) || loading}
          widthVariant="full"
          variant="big"
          loading={loading}
        >
          Kirim Email
        </Button>
      </form>
      <Modal
        type="success"
        title={`Email terkirim`}
        buttonText="OK"
        isModalActive={isModalActive}
        onModalClosed={() => setIsModalActive(false)}
        onModalConfirmed={() => setIsModalActive(false)}
        isLoading={false}
      />
    </main>
  );
};

export default ForgotPassword;
