import React from 'react';
import {ScaleLoader} from "react-spinners";
function Loading(props) {
    return (
        <div className='loading_cus'>
            <ScaleLoader color={'#E9522A'} />
        </div>
    );
}

export default Loading;