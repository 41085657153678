import React from "react";
import { AdminNav } from "../../components";
import Template from "../shared/Template";
import ComponentsTableAddOns from "./component/table";

const AddOnsList = () => {
  const component = () => {
    return (
      <div className="add-ons-management">
        <AdminNav pageTitle="Add Ons Management" />
        <div className="add-ons-management__content">
          <ComponentsTableAddOns />
        </div>
      </div>
    )
  };

  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default AddOnsList;