import React, { useState } from "react";
import { BarChart2, PlusCircle } from "react-feather";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import listtail from "../../assets/img/listtail.png";
import { TransactionIcon, LogoutIcon } from "../../assets/icon";

function SideNav(props) {
  const [menu] = useState([
    {
      id: 1,
      link: "/dashboard",
      label: "Dashboard",
      isLine: true,
      icon: <BarChart2 />,
    },
    {
      id: 2,
      link: "/add-ons-management",
      label: "Add Ons Management",
      isLine: true,
      icon: <PlusCircle />,
    },
    {
      id: 3,
      link: "/admin-management",
      label: "Admin Management",
      icon: <BarChart2 />,
      isLine: true,
    },
    {
      id: 4,
      link: "/transaction-management",
      label: "Transaction Management",
      icon: <TransactionIcon />,
      isLine: true,
    },
    {
      id: 5,
      link: "/dealer-list/detail",
      label: "Dealer Management",
      icon: <BarChart2 />,
      isLine: true,
    },
    // {
    //   id: 6,
    //   link: "/profile",
    //   label: "Pengaturan Profil",
    //   icon: <BarChart2 />,
    //   isLine: true,
    // },
    // {id: 6, link: '/logout', label : 'Log Out',  icon: <BarChart2/>},
  ]);
  // const [isActiveMenu, setActiveMenu] = useState(1)

  return (
    <div className="sidenav">
      <img src={logo} alt="logo" />
      <div className="wrapmenu">
        {menu.map((res, i) => (
          <NavLink
            key={i}
            to={res.link}
            className={res.isLine ? "borderBottom" : null}
            activeClassName="active"
          >
            {window.location.pathname === res.link ? (
              <img src={listtail} alt="tail" />
            ) : null}
            <span>
              {" "}
              {res.icon} {res.label}
            </span>
          </NavLink>
        ))}
        <a href="#" onClick={() => localStorage.clear()}>
          <span>
            {" "}
            <LogoutIcon />
            Keluar
          </span>
        </a>
      </div>
    </div>
  );
}

export default SideNav;
