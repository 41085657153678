import React from "react";
import classNames from "classnames";

import "./styles.scss";

const Badge = ({ children, variant, className }) => {
  return (
    <div
      className={classNames(
        "badge",
        {
          "badge--grey": variant === "grey",
          "badge--grey-dark": variant === "grey-dark",
          "badge--yellow": variant === "yellow",
          "badge--red": variant === "red",
          "badge--blue": variant === "blue",
          "badge--green": variant === "green",
          "badge--contrast-red": variant === "contrast-red",
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
