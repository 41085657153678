import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import LogoMain from "../../../assets/img/logo.svg";
import { Button, Input, FormGroup, Modal } from "../../../components";
import Swal from "sweetalert2";
import queryString from "query-string";

import "../styles.scss";
import { setPassword } from "../forgotpass.api";
import { unwrapResult } from "@reduxjs/toolkit";

const SetPassword = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const querySearchParams = queryString.parse(search);
  const { loading } = useSelector((state) => state.forgot);
  const [isModalActive, setIsModalActive] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(),
      confirm_password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const data = {
        ...querySearchParams,
        ...values,
      };
      dispatch(setPassword(data))
        .then(unwrapResult)
        .then(() => {
          setIsModalActive(true);
        })
        .catch((err) => {
          Swal.fire("Error", `${err.message || "Login failed!"}`, "error");
        });
    },
  });

  return (
    <main className="login">
      <form className="login__form" onSubmit={formik.handleSubmit}>
        <img
          src={LogoMain}
          alt="logo-main"
          className="login__logo"
          onClick={() => history.push("/")}
        />

        {/* <div className="label-validation-error" style={{ marginBottom: "1rem" }}>
            {error}
          </div> */}

        <h2 style={{width:"100%"}}>Password Baru</h2>
        <p>
          Buat kata sandi baru. Disarankan menggunakan kombinasi huruf (a-z) dan
          angka (0-9)
        </p>

        <FormGroup>
          <Input
            label="Password"
            type="password"
            id="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="label-validation-error">
              {formik.errors.password}
            </div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Input
            label="Konfirmasi Password"
            type="password"
            id="confirm_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirm_password}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="label-validation-error">
              {formik.errors.confirm_password}
            </div>
          ) : null}
        </FormGroup>

        <Button
          type="submit"
          disabled={!(formik.isValid && formik.dirty) || loading}
          widthVariant="full"
          variant="big"
          loading={loading}
        >
          Selesai
        </Button>
      </form>
      <Modal
        type="success"
        title={`Password Direset`}
        buttonText="OK"
        isModalActive={isModalActive}
        onModalClosed={() => {
          setIsModalActive(false);
          history.push("/");
        }}
        onModalConfirmed={() => {
          setIsModalActive(false);
          history.push("/");
        }}
        isLoading={false}
      />
    </main>
  );
};

export default SetPassword;
