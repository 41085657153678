import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getTransaction = createAsyncThunk('admin/transaction', async (data)=>{
    const response = await axios.get('http://170.187.225.136:8081/orders/list', {
        params : {
            limit : 5,
            page : 1
        },
        headers : {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        },
    })
    console.log(response.data)
    return response.data
})

export const getDetailTransaction = createAsyncThunk('admin/detailtransaction', async (id)=>{
    const response = await axios.get(`http://170.187.225.136:8081/orders/detail/${id}`, {
        headers : {
            Authorization : `Basic c2FnYXJhOjMwNGZkZDMxYWY0NWI3YjAyYTI1ZTA3MGQ5ZTMwMGM0ZWU1Nzc0YmY=`
        },
    })
    console.log(response.data)
    return response.data
})