const validatePasswordUtils = (password) =>{
    const minNumberofChars = 6;
    const maxNumberofChars = 16;
    const regularExpression = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if(password.length < minNumberofChars || password.length > maxNumberofChars){
        return false;
    }
    if(!regularExpression.test(password)) {
        return false;
    }
    return true
}
export default validatePasswordUtils