import { createSlice } from "@reduxjs/toolkit";
import { getRevenue, getService } from "./dashboard.api";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dataRevenue: {},
    dataService: {},
    loading: false,
    error: {},
  },
  extraReducers: {
    [getRevenue.pending]: (state, action) => {
      state.loading = true;
    },
    [getRevenue.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataRevenue = payload.data;
    },
    [getRevenue.rejected]: (state, action) => {
      state.loading = false;
    },
    [getService.pending]: (state, action) => {
      state.loading = true;
    },
    [getService.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataService = payload.data;
    },
    [getService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default dashboardSlice.reducer;
