import axios from "axios"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const updateTransactionInfo = createAsyncThunk(
  "updateTransactionInfo/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken")
    try {
      const response = await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/update-info`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      })
      return response.data
    } catch (error) {
      console.error(error.response, "error response")
      return rejectWithValue(error)
    }
  }
)
