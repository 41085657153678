import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { AdminNav, Button, Card, Modal } from "../../components";
import { deleteAddOns, detailAddOns } from "./addOns.api";

import Template from "../shared/Template";
import { Skeleton } from "antd";
import { Edit2, Trash2 } from "react-feather";
import "./styles.scss"

const DetailAddOns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idAddOns } = useParams();

  const [dataDetailAddon, setDetailAddon] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    dispatch(detailAddOns(idAddOns))
    .then((res) => {
      setDetailAddon(res.payload.data);
      setLoading(false);
    })
  }, [dispatch, idAddOns]);

  const breadcrumbItems = [
    {
      title: "Add Ons Management",
      to: "/add-ons-management",
    },
    {
      title: "Detail",
      to: `/add-ons-management/detail/${idAddOns}`,
      noIcon: "noIcon",
    },
  ];

  const handleDelete = () => {
    setLoadingDelete(true);

    dispatch(deleteAddOns(idAddOns))
      .then((res) => {
        if(res.payload.code === 200) {
          setShowModalDelete(false);
          setShowSuccessModal(true);
          setLoadingDelete(false);
        } else {
          alert('Terjadi kesalahan, Gagal menghapus add ons dari daftar warehouse!');
          setLoadingDelete(false);
        }
      })
  };

  const component = () => {
    return (
      <div className="add-ons-management">
        <AdminNav pageTitle="Add Ons Management" breadcrumbItems={breadcrumbItems} />

        <div className="add-ons-management__content mt-5">
            <Card className="mx-4 card-content-detail">
              <Card.Content title="Informasi Add Ons">
                <Skeleton loading={loading}>
                  <div className="information">
                    <div className="image-add-ons">
                      <img className="img-addons" src={dataDetailAddon.url_photo} alt="add ons" />
                    </div>

                    <div className="info-add-ons">
                      <div className="field-info">
                        <div className="title">Nama</div>
                        <div className="value">{dataDetailAddon.name}</div>
                      </div>

                      <div className="field-info">
                        <div className="title">Harga</div>
                        <div className="value">Rp{parseFloat(dataDetailAddon.price).toLocaleString('id')}</div>
                      </div>
                      
                      <div className="field-info">
                        <div className="title">Status</div>
                        <div className="value">{dataDetailAddon.status}</div>
                      </div>
                    </div>
                  </div>

                  <div className="description">
                    <div className="title">Deskripsi</div>
                    <div className="value">{dataDetailAddon.description}</div>
                  </div>

                  <Button widthVariant="full" onClick={() => history.push(`/add-ons-management/update/${idAddOns}`)}>
                    <div className="d-flex align-items-center">
                      <Edit2 /> &nbsp; Edit
                    </div>
                  </Button>

                  <div className="delete-button" onClick={() => setShowModalDelete(true)}>
                    <Trash2 /> &nbsp; Hapus
                  </div>
                </Skeleton>
              </Card.Content> 
            </Card>
        </div>

        <Modal
          type="confirm"
          title={`Apakah Anda Yakin Ingin Menghapus Add Ons?`}
          description="Dengan menghapus add ons, anda tidak dapat mengubahnya kembali"
          buttonText={loadingDelete ? "Loading..." : "Hapus"}
          isModalActive={showModalDelete}
          onModalClosed={() => setShowModalDelete(false)}
          onModalConfirmed={() => handleDelete()}
          isLoading={false}
        />

        <Modal
          type="success"
          title="Add Ons Dihapus"
          description="Add Ons berhasil dihapus dari daftar warehouse"
          isModalActive={showSuccessModal}
          onModalClosed={() => history.push("/add-ons-management")}
          isLoading={false}
        />
      </div>
    )
  };

  return <Template noNavbar isSideNav title="Profile" component={component} />
};

export default DetailAddOns;