import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getRevenue = createAsyncThunk("getRevenue/get", async (params, {rejectWithValue}) => {
  const token = localStorage.getItem("accessToken")
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/summary/revenue`,
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response, "error response");
    return rejectWithValue(error.response);
  }
});

export const getService = createAsyncThunk("getService/get",async(params, {rejectWithValue})=>{
  const token = localStorage.getItem("accessToken")
  try{
    const response = await axios({
      method:"get",
      url:`${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/summary/service`,
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data
  }catch (error){
    return rejectWithValue(error.response)
  }
})
