import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { postAdmin } from "../adminManagement.api";

import {
  FormGroup,
  Input,
  Button,
  Card,
  AdminNav,
  Modal,
} from "../../../components";

import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";
// import Wheel from "../../../assets/img/bengkel.png";
import Template from "../../shared/Template";

const KonfirmasiAdminDealer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { dataBodyCreate } = useSelector((state) => state.admin);
  const { accessTokenBody } = useSelector((state) => state.token);

  const breadcrumbItems = [
    {
      title: "Admin Management",
      to: "/admin-management",
    },
    {
      title: "Tambah Admin",
      noIcon: "noIcon",
      to: "/admin-management/add",
    },
  ];

  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false);

  const handleBack = () => {
    history.push("/add-admin-management");
  };

  const handleKonfir = () => {
    const data = {...dataBodyCreate,dealer_id : accessTokenBody.dealer_id}
    console.log(data)
    dispatch(postAdmin(data)).then(() => {
      setIsSuccessModalActive(true);
    });
  };

  let component = () => {
    return (
      <>
        <AdminNav breadcrumbItems={breadcrumbItems} />
        <LayoutStockV2>
          <Card.Content title="Konfirmasi Info Admin">
            <FormGroup>
              <Input
                disabled
                noWrapper
                type="text"
                label="NIK"
                value={dataBodyCreate.nik}
              />
            </FormGroup>

            <div className="form-size--half mt-4">
              <FormGroup>
                <Input
                  disabled
                  noWrapper
                  type="text"
                  label="Nama Admin"
                  value={dataBodyCreate.name}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  disabled
                  noWrapper
                  type="text"
                  label="Role"
                  value={
                    dataBodyCreate.type === "admin"
                      ? "Admin HQ"
                      : "Admin Dealer"
                  }
                />
              </FormGroup>
            </div>

            <div className="form-size--half mt-4">
              <FormGroup>
                <Input
                  disabled
                  noWrapper
                  type="text"
                  label="Email Admin"
                  value={dataBodyCreate.email}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  disabled
                  noWrapper
                  type="text"
                  label="Nomor Telepon"
                  value={dataBodyCreate.phone_number}
                />
              </FormGroup>
            </div>

            {/* <div className="form-size--half  ms-3">
              <FormGroup>
                <img
                  src={
                    dataDealer.photo_url === "" ? Wheel : dataDealer.photo_url
                  }
                  alt="wheel"
                  className="img--large mt-1"
                />
                <p
                  className="mt-1"
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#1A2D47",
                  }}
                >
                  {dataDealer.name}
                </p>
                <p className="mt-1">
                  {dataDealer.address !== "" ? dataDealer.address : "-"}
                </p>
              </FormGroup>
            </div> */}

            <div
              className="form-size--one"
              style={{ borderBottomWidth: 0, marginTop: "2.5em" }}
            >
              <Button variant="full" onClick={() => handleKonfir()}>
                <span style={{ color: "#fff" }}>Konfirmasi</span>
              </Button>
            </div>

            <div className="form-size--one" style={{ borderBottomWidth: 0 }}>
              <Button variant="white" onClick={() => handleBack()}>
                <span style={{ color: "#E9522A" }}>Kembali</span>
              </Button>
            </div>
          </Card.Content>

          <Modal
            type="success"
            title="Berhasil Membuat Admin Dealer"
            isModalActive={isSuccessModalActive}
            onModalClosed={() => history.push("/admin-management")}
            isLoading={false}
          />
        </LayoutStockV2>
      </>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default KonfirmasiAdminDealer;
