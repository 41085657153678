import { StarFilled } from "@ant-design/icons";
import React from "react";
import Wheel from "../../assets/img/bengkel.png";
import noImage from "../../assets/img/no-image-icon-23485.png"

function DetailImage({ text, isStarred, srcImage }) {
  return (
    <div>
      <p>{text}</p>
      <img src={srcImage? srcImage :Wheel} alt="wheel" className="img--large mt-1" onError={(e)=>{
        e.target.src = noImage
      }}/>
      <p
        className="mt-1"
        style={{
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "24px",
          color: "#1A2D47",
        }}
      >
        
      </p>
      {isStarred && (
        <div className="mt-1">
          <StarFilled style={{ color: "#FF9A01" }} />
          4.5
        </div>
      )}
    </div>
  );
}

export default DetailImage;
