import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListAdmin = createAsyncThunk(
  "getListAdmin/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/user/`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailAdmin = createAsyncThunk(
  "getDetailAdmin/get",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/user/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "deleteAdmin/get",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/user/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postAdmin = createAsyncThunk(
  "postAdmin/post",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/user/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const putAdmin = createAsyncThunk(
  "postAdmin/post",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/user/${data.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bodyCreate = createAsyncThunk("bodyCreate/get", async (data) => {
  return data;
});

export const dataDealer = createAsyncThunk("dataDealer/get", async (data) => {
  return data;
});
