import {
  Container,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ArrowUp, Minus, Plus, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Template from "../shared/Template";
import { getProfileById } from "./profile.api";

function Profile(props) {
  let jam = ["senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"];
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [values, setValues] = useState({
    id: "",
    name: "",
    phone_number: "",
    email: "",
    photo: "",
    type: "",
    address_id: "",
    address_detail: "",
    postal_code: "",
    long_lat: "",
  });
  useEffect(() => {
    dispatch(getProfileById(localStorage.getItem("id")));
    setValues(profileData);
  }, [profileData]);

  let component = () => {
    return (
      <div className="profile">
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item md={7}>
              <div className="boxprofile">
                <p className="title">Profile Bengkel</p>
                <form>
                  <Grid container>
                    <Grid item md={12}>
                      <TextField
                        // error={touched.email && errors.email ? true : false}
                        // helperText={touched.email && errors.email}
                        className="inp"
                        name=""
                        type="text"
                        variant="outlined"
                        value={values.name}
                        // onChange={(e)=>setEmail(e.target.value)}
                        label="Nama Bengkel"
                      />
                    </Grid>

                    <Grid item md={12}>
                      <TextField
                        className="inp"
                        name=""
                        type="text"
                        variant="outlined"
                        value={values.address_detail}
                        multiline
                        rows={3}
                        label="Alamat Bengkel"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        className="inp"
                        name=""
                        type="text"
                        variant="outlined"
                        label="Pilih Kota"
                      />
                    </Grid>
                  </Grid>

                  <hr />
                  <p className="title">Kontak</p>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextField
                        className="inp"
                        name=""
                        type="text"
                        value={values.phone_number}
                        variant="outlined"
                        label="Nomor Telepon 1"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Button variant="contained" className="add-kontaknum">
                        <Plus color="white" size={50} />
                      </Button>
                    </Grid>

                    <Grid item md={12}>
                      <TextField
                        className="inp"
                        name=""
                        value={values.email}
                        type="email"
                        variant="outlined"
                        label="Email"
                      />
                    </Grid>
                  </Grid>
                </form>
              </div>

              <div className="operasional">
                <p className="title">Jam Operasional</p>
                {jam.map((res, i) => (
                  <div className="wrapclock" key={i}>
                    <FormControlLabel
                      className="checkboxcus"
                      control={<Checkbox name="name" className="check" />}
                      label={res}
                    />
                    <div className="optime">
                      <TextField
                        className="inp"
                        name=""
                        type="text"
                        variant="outlined"
                        select
                        value="10"
                        size="small"
                      >
                        <MenuItem value="10">09.00</MenuItem>
                        <MenuItem value="20">17.00</MenuItem>
                      </TextField>
                      <Minus size={25} color="#455673" />
                      <TextField
                        className="inp"
                        name=""
                        type="text"
                        variant="outlined"
                        select
                        value="20"
                        size="small"
                      >
                        <MenuItem value="10">09.00</MenuItem>
                        <MenuItem value="20">17.00</MenuItem>
                      </TextField>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="boxcover">
                <p className="title">Foto Covers</p>

                <img src={"https://picsum.photos/300/210"} alt="product" />
                <div className="wrapupload">
                  <Button variant="contained" size="small">
                    <ArrowUp color="white" size={20} />
                    Upload
                  </Button>
                  <div className="trash">
                    <Trash2 size={18} /> Hapus
                  </div>
                </div>
              </div>
              <div className="buttons">
                <Button variant="contained">Simpan</Button>
                <Button variant="contained">Batal</Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  };
  return <Template isSideNav title="Profile" component={component} />;
}

export default Profile;
