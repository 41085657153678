const currencyUtil = {
  formatRupiah(amount) {
    if (amount !== undefined) {
      var reverse = amount.toString().split("").reverse().join("");
      var formatted = reverse.match(/\d{1,3}/g);
      formatted = formatted.join(".").split("").reverse().join("");
      return formatted;
    }
    return amount;
  },
};

export default currencyUtil;
