import { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Card, FormGroup, Input } from ".."
import "./styles.scss"
import { X } from "react-feather"
import { useDispatch } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { deleteFile, uploadFile } from "services/fileService"
import { updateTransactionInfo } from "pages/TransactionDetail/transaction-detail.api"

const TransactionInfoModal = ({ data, disabled = false }) => {
  const dispatch = useDispatch()

  const [isModalActive, setIsModalActive] = useState(false)
  const [isPhotoUpdate, setIsPhotoUpdate] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const toggleModal = () => {
    setIsModalActive(!isModalActive)
  }

  const formik = useFormik({
    initialValues: {
      note_dealer: "",
      image_urls: [],
      images: [],
      previous_image_urls: [], // Keep this optional
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      note_dealer: Yup.string(),
      image_urls: Yup.array().of(Yup.string()).required("Image URLs is required"),
      images: Yup.array().of(
        Yup.mixed().test("fileType", "Invalid file format", (value) => {
          if (value && value.type) {
            return ["image/jpeg", "image/png", "image/gif"].includes(value.type)
          }
          return false
        })
      )
    }),
    onSubmit: async (values) => {
      setIsUpdating(true)
      const { note_dealer, image_urls, previous_image_urls = [], images } = values

      let payload = {
        dealer_id: data.dealer_id,
        order_id: data.order_address.order_id,
        note_dealer,
        images,
        image_urls,
      }

      if (isPhotoUpdate) {
        try {
          // Handle deletion if previous images exist
          if (previous_image_urls.length > 0) {
            await Promise.all(previous_image_urls.map((url) =>
              dispatch(deleteFile({ file_url: url })).catch((err) => {
                console.error(err, "Failed delete old product receival photo")
                alert("Failed delete old product receival photo", err)
              })
            ))
          }

          // Upload new images
          const uploadedUrls = await Promise.all(
            images.map((image) => {
              const payloadFile = {
                file: image,
                path: "brands",
                overwrite: false,
                previous_file: "",
              }
              return dispatch(uploadFile(payloadFile))
                .then(unwrapResult)
                .then((res) => res.data.file_url)
            })
          )

          payload.image_urls = uploadedUrls

          // API Call to update transaction info
          await dispatch(updateTransactionInfo({
            ...payload,
            images: uploadedUrls
          })).then(unwrapResult)

          console.info("Success Update transaction info")
          window.location.reload()
        } catch (err) {
          console.error("Failed Update transaction info", err)
          alert("Update transaction info was failed", err)
        }
      } else {
        // No photo updates
        try {
          await dispatch(updateTransactionInfo(payload))
            .then(unwrapResult)

          console.info("Success Update transaction info")
          window.location.reload()
        } catch (err) {
          console.error("Failed Update transaction info", err)
          alert("Update transaction info was failed", err)
        }
      }

      setIsUpdating(false)
      formik.resetForm()
    },
  })

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    const newImages = []
    const newImageUrls = []

    files.forEach((file) => {
      newImages.push(file)
      newImageUrls.push(URL.createObjectURL(file))
    })

    formik.setFieldValue("images", newImages)
    formik.setFieldValue("image_urls", newImageUrls)
    setIsPhotoUpdate(true)
  }

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      note_dealer: data?.dealer_infromation?.note_dealer || "",
      previous_image_urls: data?.dealer_infromation?.images || [],
    })
  }, [data])

  return (
    <>
      {disabled ? (
        <p>Edit</p>
      ) : (
        <p className='text-primary' onClick={() => setIsModalActive(true)}>
          Edit
        </p>
      )}

      {isModalActive && (
        <Card className='general-modal'>
          <X
            className='general-modal__close'
            onClick={() => setIsModalActive(false)}
          />
          <Card.Content
            className='general-modal__content'
            title='Laporan Penerimaan'
          >
            <form className='content-form' onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Input
                  type='textarea'
                  id='note_dealer'
                  label='Catatan Penerimaan'
                  name='note_dealer'
                  onChange={formik.handleChange}
                  value={formik.values.note_dealer}
                />
                {formik.errors.note_dealer && (
                  <div className='label-validation-error'>
                    {formik.errors.note_dealer}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type='file'
                  id='images'
                  label='Foto Penerimaan'
                  name='images'
                  onChange={handleImageChange}
                  multiple={true}
                />
                {formik.errors.images && (
                  <div className='label-validation-error'>
                    {formik.errors.images}
                  </div>
                )}
                {formik.values.image_urls.length > 0 && (
                  <div className='image-preview'>
                    {formik.values.image_urls.map((image, index) => (
                      <div className='image-preview__wrapper' key={index}>
                        <img
                          className='image-preview__image'
                          src={image}
                          alt={`preview-${index}`}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </FormGroup>

              <Button
                type='submit'
                variant={
                  Object.keys(formik.errors).length > 0 || isUpdating
                    ? "grey"
                    : "primary"
                }
                disabled={Object.keys(formik.errors).length > 0 || isUpdating}
              >
                {isUpdating ? "mengupdate..." : "Kirim"}
              </Button>
            </form>
          </Card.Content>
        </Card>
      )}

      {isModalActive && <div className='modal-overlay' onClick={toggleModal} />}
    </>
  )
}

export default TransactionInfoModal
