import React from "react";

import { AdminNav } from "../../components";
import Template from "../shared/Template";
import DealerTable from "./components/DealerTable";

import "./styles.scss";

const DealerManagement = () => {
  let component = () => {
    return (
      <div className="dealer-management">
        <AdminNav pageTitle="Dealer Management" />
        <div className="dealer-management__content">
          <DealerTable />
        </div>
      </div>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default DealerManagement;
