import React, { useEffect, useState } from "react";
import { AdminNav } from "components";
import "./styles.scss";
import IconBoxClose from "assets/icon/IconBoxClose.svg";
import IconBoxCloseGray from "assets/icon/IconBoxCloseGray.svg";
import { useHistory } from "react-router";
import Template from "pages/shared/Template";
import {
  useNotificationList,
  useNotificationUpdate,
  useNotificationsReadAll,
} from "hooks/use-notification";

const NotificationCenter = () => {
  const history = useHistory();
  const { data: getNotification } = useNotificationList();
  const { mutate } = useNotificationUpdate();
  const { mutate: readNotificationAll } = useNotificationsReadAll({
    onSuccess: () => {
      history.go(0);
    },
    onError: () => {
      console.log("error");
    },
  });
  const onSubmit = (id) => {
    console.log(id);
    mutate({ id });
  };
  const handleReadAll = () => {
    readNotificationAll();
  };
  const component = () => {
    return (
      <>
        <AdminNav pageTitle="Notification Center" />
        <div
          className="px-4 pt-2 d-flex justify-content-end detail-button"
          onClick={handleReadAll}
        >
          Lihat Semua
        </div>
        <div className="p-4">
          {getNotification?.data?.notifications?.length == 0 ? (
            <center>
              <div>Tidak Ada Notifikasi</div>
            </center>
          ) : (
            getNotification?.data?.notifications?.map((item) => {
              return (
                <div
                  className={`nofification-item ${
                    item?.already_read == false ? "active" : ""
                  } shadow p-3 mb-3`}
                >
                  <div className="d-flex justify-content-start align-items-start">
                    <div
                      className={`icon-wrapper ${
                        item?.already_read == false ? "active" : ""
                      }`}
                    >
                      <img src={IconBoxClose} alt="notif" />
                    </div>
                    <div className="ms-4">
                      <h5 className="title mb-1"> {item.notification.title}</h5>
                      <p className="description mb-2">
                        {item.notification.body}
                      </p>
                      {/* <span className="time">{item?.id}</span> */}
                    </div>
                    {item?.link && (
                      <div
                        className="ms-auto detail-button"
                        onClick={() => {
                          {
                            onSubmit(item.id);
                            history.push(
                              `/notification-center/detail/${item?.link
                                ?.split("://")[1]
                                .split("/")
                                .slice(1)
                                .join("/")}`
                            );
                          }
                        }}
                      >
                        Lihat Detail
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </>
    );
  };
  return (
    <Template
      noNavbar
      isSideNav
      title="Notification Center"
      component={component}
    />
  );
};

export default NotificationCenter;
