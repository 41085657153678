import React, { useEffect } from "react";
import { TextField, Button, InputAdornment } from "@material-ui/core";
import { Eye, EyeOff } from "react-feather";
import { useState } from "react";
import logo from "../../assets/img/logo.svg";

import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/Loading";
import Swal from "sweetalert2";
import { postLogin } from "./login.api";
import validateEmailUtils from "../../utils/validateEmailUtils";
import { Link } from "react-router-dom";

function Login(props) {
  const dispatch = useDispatch();

  const [visiblePass, setVisiblePass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isTouchedEmail, setTouchedEmail] = useState(false)
  const [isToucherdPassword,setTouchedPassword] = useState(false)
  
  let isValidForm = false;
  let isValidEmail , isValidPassword = false;
  let errorEmail, errorPassword = false
  let errEmailMessage,errPassowordMessage

  errorEmail = false
  errEmailMessage = ""
  if (isTouchedEmail && !validateEmailUtils(email)){
    errorEmail = true
    errEmailMessage = "Invalid Email"
  }
  const onBlurEmail = () =>{
    setTouchedEmail(true);
  }

  errorPassword = false
  errPassowordMessage = ""
  if (isToucherdPassword && password.length <= 2){
    errorPassword = true
    errPassowordMessage = "Invalid Password"
  }
  const onBlurPassword = () =>{
    setTouchedPassword(true)
  }

  if (validateEmailUtils(email) && isTouchedEmail){
    isValidEmail = true;
  }

  if (password.length > 2) {
    isValidPassword = true;
  }

  if (isValidEmail && isValidPassword){
    isValidForm = true
  }

  const { login, status } = useSelector((state) => state.login);

  let handleSubmit = (e) => {
    const body = {
      email,
      password,
    };
    dispatch(postLogin(body))
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          try {
            if(res.data.type != "dealer"){
              throw new Error('Only Admin Dealer Can Login Here')
            }
            localStorage.setItem("auth", "true");
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("accessToken", res.data.access_token);
            localStorage.setItem("refreshToken", res.data.refresh_token)
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "Login success",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
          } catch (error) {
            Swal.fire("Error", `${error.message || "Login failed!"}`, "error");
          }
        }
        if (res.code !== 200) {
          Swal.fire("Error", `${res.data.message || "Login failed!"}`, "error");
        }
      });
    e.preventDefault();
  };

  return (
    <>
      {status ? <Loading /> : null}
      <div className="login">
        <form onSubmit={handleSubmit}>
          <img src={logo} alt="logo" />
          <h6>Admin Dealer login</h6>
          <h3>
            <i>SIGN IN</i>
          </h3>
          <TextField
            // error={touched.email && errors.email ? true : false}
            // helperText={touched.email && errors.email}
            error = {errorEmail}
            helperText = {errEmailMessage}
            className="inpLogin"
            name="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={onBlurEmail}
            label="Email"
            in
          />
          <TextField
            // error={touched.password && errors.password ? true : false}
            // helperText={touched.password && errors.password}
            error = {errorPassword}
            helperText = {errPassowordMessage}
            className="inpLogin"
            name="password"
            type={visiblePass ? "text" : "password"}
            variant="outlined"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={onBlurPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {visiblePass ? (
                    <Eye onClick={() => setVisiblePass(!visiblePass)} />
                  ) : (
                    <EyeOff onClick={() => setVisiblePass(!visiblePass)} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Button size="large" variant="contained" type="submit" disabled={!isValidForm}>
            SUBMIT
          </Button>

          <Link to="/lupa-password">Lupa Password</Link>
        </form>
      </div>
    </>
  );
}

export default Login;
