import {Button, TextField, FormControlLabel, Grid, Checkbox} from '@material-ui/core'
import React, {useEffect} from 'react';
import Template from '../../shared/Template';
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment';
import Loading from '../../shared/Loading';
import { Calendar, ChevronLeft } from 'react-feather';
import {Link} from 'react-router-dom'
import { getTransaction } from '../transaction.api';



function DetailTransaction(props) {
    let dispatch = useDispatch()
    const {
        detailTransactionData,
        detailAddress,
        detailPayment,
        status,
        orderList
    } = useSelector(state => state.transaction)
    const dummy = [
        {id: 1, img:'https://picsum.photos/78/78/?blur', brand : 'Achiles', name : 'ATR Sport 2', diameter:'Diameter ø 17, 17x7, +45mm', qty: 4, price : '1.234.567' }, 
        {id: 2, img:'https://picsum.photos/78/78/?blur', brand : 'Achiles', name : 'ATR Sport 2', diameter:'Diameter ø 17, 17x7, +45mm', qty: 4, price : '1.234.567' }, 
        {id: 3, img:'https://picsum.photos/78/78/?blur', brand : 'Achiles', name : 'ATR Sport 2', diameter:'Diameter ø 17, 17x7, +45mm', qty: 4, price : '1.234.567' }, 
        {id: 4, img:'https://picsum.photos/78/78/?blur', brand : 'Achiles', name : 'ATR Sport 2', diameter:'Diameter ø 17, 17x7, +45mm', qty: 4, price : '1.234.567' }, 
    ]
    useEffect(() => {
        dispatch(getTransaction(props.match.params.idTransaction))
    },[])
    // console.log(detailTransactionData)
    // console.log(detailAddress)
    // console.log(detailPayment)

    const component = ()=>{
        return(
            <>
            {status === 'loading'? <Loading /> : null}
            <div className="transaction-detail">
                <div className="box">
                    <div className="header">
                        <Link to='/transaction' className="backbutton">
                            <ChevronLeft /> Kembali
                        </Link>
                        <div className="deschead">
                            <div>
                                <Button disabled variant='contained' size='small' >Belum di proses</Button>
                            </div>
                            <div>
                                <p className="title">Pelanggan :</p>
                                {/* {detailTransactionData.orderid} */}
                                dsffsdf
                            </div>
                            <div>
                                <p className="title">Nomor Pesanan :</p>
                                {/* Kamis, 10 April 2021 */}
                                {/* {moment(new Date(detailTransactionData.created_at)).format('dddd , DD MMMM YYYY, hh:mm')} */}
                                dsfsdfsdf
                            </div>
                            <div>
                                <p className="title">Waktu Pesanan Dibuat :</p>
                                {/* {detailAddress.title} */}
                                jksdhfkjshdf
                            </div>
                            <div>
                                <p className="title">Metode Pengiriman :</p>
                                {/* {detailTransactionData.Shipping} */}
                                sdfsdfsdf
                            </div>
                        </div>
                    </div>

                    <Grid container className='list'>
                        <Grid item md={6} className='desc' >
                        {
                            [1,1,1].map((res,i)=>(
                                    <div className="wrapdesc"key={i}>
                                        <div className="left">
                                            <img src={'https://picsum.photos/75/75'} alt="product" />
                                            <div>
                                                <div className="brand">Achiless</div>
                                                <div className="name">ATR Sport 2</div>
                                                <div className="diameter">Diameter ø 17, 17x7, +45mm</div>
                                            </div>
                                        </div>

                                        <div className="right">
                                            <p>Jumlah</p>
                                            X4
                                        </div>

                                    </div>
                            ))
                        }
                        </Grid>

                        <Grid item md={6} className='addon'>
                            <p className='title'>Add-on service</p>
                            <div className="listaddon">
                                <p className='name'>Balancing</p> <div className="price">Rp 345.678</div>
                            </div>
                            <div className="listaddon">
                                <p className='name'>Alignment</p> <div className="price">Rp 345.678</div>
                            </div>
                            <div className="listaddon">
                                <p className='name'>Valve <span>Rubber</span></p> <div className="price">Rp 345.678</div>
                            </div>
                            <div className="listaddon">
                                <p className='name'>Lugnuts</p> <div className="price">Rp 345.678</div>
                            </div>
                            <div className="desclug">
                                <img src={'https://picsum.photos/75/75'} alt="product" />
                                <div>
                                    <div className="brand">HSR</div>
                                    <div className="name">AWheel Lug Nuts</div>
                                    <div className="diameter">Red - Chrome</div>
                                </div>
                            </div>

                        </Grid>
                    </Grid>
                </div>

                <div className="box2" >
                    <Grid container className='notebox' spacing={5} style={{border: 'none', marginBottom: 0}}>
                        <Grid item md={6} className='desc' >
                            <div className="title">Catatan Pemansangan</div>
                            <p className="note">Saya akan ke bengkel hari sabtu</p>
                        </Grid>
                        <Grid item md={6} className='buttonreceived' >
                            <Button variant='contained'>Terima pesanan</Button>
                        </Grid>
                    </Grid>
                </div>


                <div className="box2">
                    <Grid container className='notebox' spacing={5}>
                        <Grid item md={6} className='desc' >
                            <div className="title">Catatan Pemansangan</div>
                            <p className="note">Saya akan ke bengkel hari sabtu</p>
                        </Grid>
                        <Grid item md={6} className='sch' >
                            <div className="title">
                                Jadwal Pemansangan
                                <Checkbox className='checkcus'  name="name" />
                            </div>
                            <div className="settingdate">
                                <p>Pengaturan jadwal</p>
                                <p>Sabtu, 12 April 2021 <Calendar /></p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item md={12} className='buttonreceived' >
                            <Button variant='contained'>Terima barang</Button>
                        </Grid>
                    </Grid>
                </div>

                <div className="box2">
                    <Grid container className='notebox' spacing={5}>
                        <Grid item md={6} className='desc' >
                            <div className="title">Catatan Pemansangan</div>
                            <p className="note">Saya akan ke bengkel hari sabtu</p>
                        </Grid>
                        <Grid item md={6} className='sch' >
                            <div className="title">
                                Jadwal Pemansangan
                                <Checkbox className='checkcus'  name="name" />
                            </div>
                            <div className="settingdate">
                                <p>Pengaturan jadwal</p>
                                <p>Sabtu, 12 April 2021 <Calendar /></p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className='reportbox' spacing={5}>
                        <Grid item md={6} className='report' >
                            <div className="title">Laporan Pesanan</div>
                            <div className="received">
                                <p>Diterima</p>
                                <p>Sabtu, 12 April 2021</p>
                            </div>
                        </Grid>
                        <Grid item md={6} className='photos'>
                            <div className="title">Foto Penerimaan Produk</div>
                            <div className="photobox">
                                <img src={'https://picsum.photos/75/75'} alt="product" />
                                <img src={'https://picsum.photos/75/75'} alt="product" />
                                <img src={'https://picsum.photos/75/75'} alt="product" />
                                <img src={'https://picsum.photos/75/75'} alt="product" />

                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>
            </>
        )
    }
    return <Template isSideNav backLink='/transaction' component={component} title='Detail Transaction'/>;
}

export default DetailTransaction;
// {/* <div className="listprod">
//     {
//         orderList.map((res,i)=>(
//             <div className="list" key={i}>
//                 <div className="wrapdesc">
//                     <img src={'http://170.187.225.136:9001/products/'+JSON.parse(res.product_image)[0]} alt="product" />
                   
//                     <div>
//                         <div className="brand">{res.brand_name}</div>
//                         <div className="name">{res.product_name}</div>
//                         <div className="diameter">{`Diameter ø ${res.profile}`}</div>
//                     </div>

//                 </div>
//                 <div className="qty">
//                     <p>Jumlah</p>
//                     X{res.total}
//                 </div>
//                 <p className="price">
//                     Rp. {res.price_total}
//                 </p>
//             </div>
//         ))
//     }
//     <div className="total">
//         <p>Total Pesanan : <span>Rp {detailTransactionData.Price}</span></p>
//         <Button variant='outlined'>Proses Pesanan</Button>
//     </div>
// </div> */}