import { Grid, Container } from "@material-ui/core";
import React from "react";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import bgsidenav from "../../assets/img/bgsidenav.svg";

function Template(props) {
  return (
    <div className="template">
      <div
        className="wrap_sidebar"
        style={{ backgroundImage: `url(${bgsidenav})` }}
      >
        {props.isSideNav ? <SideNav /> : null}
      </div>
      <div className="wrap_contain" onScroll={props.scrollEvent ? (props.onScroll):(()=>{})}>
        {props.noNavbar ? (
          ""
        ) : (
          <Navbar backLink={props.backLink} title={props.title} />
        )}
        <div className="conten">
          {props.component ? props.component() : null}
        </div>
      </div>
    </div>
  );
}

export default Template;
