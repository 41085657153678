import { createSlice } from '@reduxjs/toolkit';
import { storeToken } from './accesToken.api';

const accessTokenSlice = createSlice({
  name: "token",
  initialState: {
    accessTokenBody:{},
  },
  extraReducers: {
    [storeToken.fulfilled]: (state, { payload }) => {
      state.accessTokenBody = payload
    },
  }
});

export default accessTokenSlice.reducer;