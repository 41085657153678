import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListDealer = createAsyncThunk(
  "getListDealer/get",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/dealer`,
        params: {
          limit: 10,
          page: 1,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const deleteDealer = createAsyncThunk(
  "deleteDealer/delete",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const { id } = data;
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/dealer/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getDetailDealer = createAsyncThunk(
  "getDetailDealer/get",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/dealer/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const createDealer = createAsyncThunk(
  "createDealer/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/dealer`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      console.log("data", data);
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const updateDealer = createAsyncThunk(
  "updateDealer/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/dealer/${data.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);