import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListTransaction = createAsyncThunk('getListTransaction/get', async (params, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction`,
            params: params,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const getDetailTransaction = createAsyncThunk('getDetailTransaction/get', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    const { id } = data
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const processTransaction = createAsyncThunk('processTransaction/put', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    const { id } = data
    try {
        const response = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/process/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const processDealerTransaction = createAsyncThunk('processDealerTransaction/put', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    const { id } = data.id
    const statusNumber = data.statusNumber
    console.log(data)
    try {
        const response = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}/dealer/process/${data.statusNumber}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})