import React, { useState } from "react";

import "./styles.scss";

const Dropdown = ({
  label,
  placeholder,
  items,
  onChange,
  selectedItem,
  noWrapper,
  labelNoWrapper,
  defaultValue,
}) => {
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!isOpen);

  return (
    <div className="dropdown">
      <label>{label}</label>
      {labelNoWrapper && <p className="dropdown-labelNoWrapper">{labelNoWrapper}</p>}
      <div
        className={noWrapper ? "dropdown-header-no-wrapper" : "dropdown-header"}
        onClick={toggleDropdown}
      >
        {selectedItem ? (
          items.find((item) => item.value === selectedItem).label
        ) : defaultValue ? (
          <>
            <span>{defaultValue}</span>
          </>
        ) : (
          <>
            <span className="dropdown-header__placeholder">{placeholder}</span>
          </>
        )}

        <svg
          className={`icon ${isOpen && "open"}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 9L12 15L6 9"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {items ? (
          items.map((item, i) => (
            <div
              className="dropdown-item"
              onClick={() => {
                toggleDropdown();
                onChange(item.value);
              }}
              id={item.value}
              key={i}
            >
              <span className={`dropdown-item-dot ${item.value === selectedItem && "selected"}`}>
                •{" "}
              </span>
              {item.label}
            </div>
          ))
        ) : (
          <span>Empty data</span>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
