import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../pages/login/login.reducer.js";
import profileReducer from "../pages/profile/profile.reducer.js";
import transactionReducer from "../pages/TransactionManagement/transaction.reducer";
import adminManagementReducer from "../pages/AdminManagement/adminManagement.reducer";
import dealerReducer from "../pages/AdminManagement/dealer.reducer";
import regencyReducer from "../pages/region/regency.reducer";
import districtReducer from "../pages/region/district.reducer";
import provinceReducer from "../pages/region/province.reducer.js";
import cityReducer from "../pages/region/city.reducer.js";
import refreshTokenReducer from "../pages/login/refreshToken.reducer.js";
import accessTokenReducer from "../pages/accesToken.reducer.js";
import fileServiceReducer from "../services/fileService.reducer.js";
import forgotPassReducer from "../pages/ForgotPassword/forgotpass.reducer.js"
import dashboardReducer from "../pages/dashboard/dashboard.reducer.js";
// import counterReducer from './counter'

export default configureStore({
  reducer: {
    login: loginReducer,
    transaction: transactionReducer,
    profile: profileReducer,
    admin: adminManagementReducer,
    dealer: dealerReducer,
    district: districtReducer,
    regency: regencyReducer,
    city: cityReducer,
    province: provinceReducer,
    refreshToken: refreshTokenReducer,
    token: accessTokenReducer,
    upload: fileServiceReducer,
    forgot:forgotPassReducer,
    dashboard:dashboardReducer,
  },
});
