import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const postLogin = createAsyncThunk("login/post", async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_DASHBOARD_SERVICE_URL+"/dashboard/user/login",
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.response, "error response");
    return error.response;
  }
});
