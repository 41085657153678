import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProfileById = createAsyncThunk('partner/getProfileById', async (id)=>{
    const response = await axios.get('http://170.187.225.136:8004/partner/get', {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        },
        params : {
            id: id
        }
    })
    console.log(response.data)
    return response.data
})