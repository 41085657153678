import { createSlice } from "@reduxjs/toolkit";
import { getProfileById } from "./profile.api";


const profileSlice = createSlice({
    name : 'profile',
    initialState : {
        profileData :{},
        loading : false,
        error : null
    },
    extraReducers : {
        [getProfileById.pending]: (state, action)=>{
            state.loading = true
        }, 
        [getProfileById.fulfilled]: (state, {payload})=>{
            state.loading = false
            state.profileData = payload.data
        }, 
        [getProfileById.rejected]: (state, action)=>{
            state.loading = false
            state.error = action.payload
        },  
    }

})

export default profileSlice.reducer