import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListTransaction = createAsyncThunk('getListTransaction/get', async (params, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction`,
            params: params,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const getDetailTransaction = createAsyncThunk('getDetailTransaction/get', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    const { id } = data
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})