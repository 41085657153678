import { Grid, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Template from "../shared/Template";
import moment from "moment";
import 'moment/locale/id';

import DatePicker, { getDate } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getRevenue, getService } from "./dashboard.api";
import { getDetailDealer } from "../AdminManagement/dealer.api";
import jwtDecode from "jwt-decode";
import { AdminNav } from "../../components";
import { getListTransaction } from "../TransactionManagementV2/transaction.api";
import { useHistory } from "react-router-dom";
function Dashboard(props) {
  const token = localStorage.getItem("accessToken");
  const decodedToken = jwtDecode(token);
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [isDateDisplayed, setIsDateDisplayed] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [params, setParams] = useState({
    month: startDate.getMonth() + 1,
    dealer_id: decodedToken.dealerId,
    year: startDate.getFullYear(),
  });

  const [trParams, setTrParams] = useState({
    order: "created_at,desc",
    limit: 5,
    page: 1,
    deliv_loc: "dealer",
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(startDate).format("YYYY-MM-DD"),
  });

  const {
    dataRevenue,
    dataService,
    loading: dashboardLoading,
  } = useSelector((state) => state.dashboard);

  const { detailDealer, loading: dealerLoading } = useSelector(
    (state) => state.dealer
  );

  const { loading: transactionLoading, totalItem } = useSelector(
    (state) => state.transaction
  );

  useEffect(() => {
    const paramsSummary = {
      ...params,
      month: startDate.getMonth() + 1,
      year: startDate.getFullYear(),
    };
    dispatch(getRevenue(paramsSummary));
    dispatch(getService(paramsSummary));
    dispatch(getDetailDealer(decodedToken.dealerId));
    dispatch(
      getListTransaction({
        ...trParams,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(startDate).format("YYYY-MM-DD"),
      })
    )
      .unwrap()
      .then((res) => {
        setTransactionData(res.data.transactions);
      });
  }, [dispatch, startDate]);

  const handleTransactionScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (transactionData.length >= totalItem) {
        return;
      }
      const newParams = {
        ...trParams,
        page: trParams.page + 1,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(startDate).format("YYYY-MM-DD"),
      };
      dispatch(getListTransaction(newParams))
        .unwrap()
        .then((res) => {
          setTransactionData([...transactionData, ...res.data.transactions]);
        });
    }
  };

  const handlePrevMonth = () => {
    const newDate = new Date(startDate.setMonth(startDate.getMonth() - 1));
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    setStartDate(firstDay);
    setIsDateDisplayed(false)
  };

  const handleNextMonth = () => {
    const newDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    setStartDate(firstDay);
    setIsDateDisplayed(false)
  };

  let component = () => {
    return (
      <>
        <AdminNav pageTitle="Dashboard Management" />
        <div className="dashboard">
          {/* <Container maxWidth={false} > */}
          <Grid container spacing={3}>
            <Grid item md={7}>
              <div className="bengkel">
                <img src={detailDealer?.photo_url} alt="bengkel" />
                <div className="box" style={{ overflowY: "scroll" }}>
                  <div className="name">{detailDealer?.name}</div>
                  <div className="address">
                    {`${detailDealer?.name}, ${detailDealer?.city_name}, ${detailDealer?.province_name}, ${detailDealer?.postal_code}`}
                  </div>
                  <div className="phone">{`${detailDealer?.phone}`}</div>
                  <div className="email">{`${detailDealer?.email}`}</div>
                </div>
              </div>

              <p className="title">Pendapatan</p>
              <Grid className="pendapatan" container spacing={3}>
                <Grid md={6} sm={12} item>
                  <div className="box">
                    <div className="head">
                      Bulanan
                      <span>{`${moment(startDate).format("MMMM YYYY")}`}</span>
                    </div>
                    <div className="value">{dataRevenue?.monthly}</div>
                  </div>
                </Grid>
                <Grid md={6} sm={12} item>
                  <div className="box">
                    <div className="head">
                      Total
                      <span>{`${moment(startDate).format("MMMM YYYY")}`}</span>
                    </div>
                    <div className="value">{dataRevenue?.total}</div>
                  </div>
                </Grid>
              </Grid>

              <p className="title">Pelayanan</p>
              <Grid className="pendapatan" container spacing={3}>
                <Grid md={6} sm={12} item>
                  <div className="box">
                    <div className="head">
                      Bulanan
                      <span>{`${moment(startDate).format("MMMM YYYY")}`}</span>
                    </div>
                    <div className="value">
                      {`${dataService?.monthly}`}
                      <span>
                        Update Terakhir <br />
                        <b>{`${moment(startDate).format("MMMM YYYY")}`}</b>
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid md={6} sm={12} item>
                  <div className="box">
                    <div className="head">
                      Total
                      <span>{`${moment(startDate).format("MMMM YYYY")}`}</span>
                    </div>
                    <div className="value">
                      {`${dataService?.total}`}
                      <span>
                        Akumulasi dari <br />
                        <b>{`${moment(startDate).format("MMMM YYYY")}`}</b>
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <div className="history">
                <div className="boxdate">
                  <div>
                    <button
                      style={{ width: "100%", fontSize: "2rem" }}
                      onClick={handlePrevMonth}
                    >
                      {" "}
                      {"<"}
                    </button>
                    <h2 onClick={()=>setIsDateDisplayed(true)}>{`${moment(startDate).locale('id').format("MMM")}`}</h2>
                    <button
                      style={{ width: "100%", fontSize: "2rem" }}
                      onClick={handleNextMonth}
                    >
                      {">"}
                    </button>
                  </div>
                  {isDateDisplayed && (
                    <div className="valdate">
                      <DatePicker
                        change
                        className="customDay"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          setTrParams({
                            ...trParams,
                            start_date: moment(startDate).format("YYYY-MM-DD"),
                            end_date: moment(startDate).format("YYYY-MM-DD"),
                          });
                        }}
                        minDate={
                          new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            1
                          )
                        }
                        maxDate={
                          new Date(
                            startDate.getFullYear(),
                            startDate.getMonth() + 1,
                            0
                          )
                        }
                        inline
                        hideHeader
                      />
                    </div>
                  )}
                </div>
                <div
                  className="boxhis"
                  style={{ overflowY: "scroll", height: "85vh" }}
                  onScroll={handleTransactionScroll}
                >
                  <div className="header">{`${moment(startDate).locale('id').format(
                    "dddd,D MMMM YYYY"
                  )}`}</div>
                  {transactionLoading && transactionData?.length === 0 ? (
                    <div>Loading...</div>
                  ) : transactionData?.length > 0 ? (
                    transactionData?.map((val, index) => {
                      return (
                        <div className="cardhis" key={index}>
                          <div className="number">
                            Order No : <b>{val.order_number}</b>{" "}
                          </div>
                          <div className="wrapdesc">
                            <img
                              src={val.order_product[0].product_image}
                              alt="product"
                            />
                            <div>
                              <div className="brand">
                                {val.order_product[0].brand_name}
                              </div>
                              <div className="name">
                                {val.order_product[0].product_name}
                              </div>
                              {val.count_another_product === 0 ? null : (
                                <div className="diameter">{`+ ${val.count_another_product} Produk Lainnya`}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>Tidak ditemukan</div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* </Container> */}
        </div>
      </>
    );
  };
  return (
    <Template noNavbar isSideNav title="Dashboard" component={component} />
  );
}

export default Dashboard;
