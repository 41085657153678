import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bodyCreate } from "../adminManagement.api";

import {
  FormGroup,
  Input,
  Button,
  Dropdown,
  Card,
  AdminNav,
} from "../../../components";
import LayoutStockV2 from "../../../components/Shared/LayoutStockV2";
import Template from "../../shared/Template";
import {useDropdownValidation, useInputValidation} from "../../../hooks/use-formValidation";
import validateEmailUtils from "../../../utils/validateEmailUtils";
import isNumberOnlyUtils from "../../../utils/isNumberObluUtils";
import validatePasswordUtils from "../../../utils/validatePasswordUtils";

const AddAdminManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [objectCreate, setObjectCreate] = useState({
    name: null,
    email: null,
    password: null,
    type: null,
    nik: null,
    phone_number: null,
    dealer_id: 0,
  });

  const {
    valueIsValid:nikIsValid,
    errorValue:errorNik,
    errMessageValue:errMessageNik,
    onChange: nikChangeHandler,
    onBlur: nikBlurHandler
  } = useInputValidation(
    (value)=>{if(value.length === 16 && isNumberOnlyUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, nik: value })},
    "Nik harus 16 digit angka"
  )

  const {
    valueIsValid:nameIsValid,
    errorValue:errorName,
    errMessageValue:errMessageName,
    onChange: nameChangeHandler,
    onBlur: nameBlurHandler
  } = useInputValidation(
    (value)=>{if(value.length > 0)return true},
    (value)=>{setObjectCreate({ ...objectCreate, name: value })},
    "Nama tidak boleh kosong"
  )

  const {
    valueIsValid:emailIsValid,
    errorValue:errorEmail,
    errMessageValue:errMessageEmail,
    onChange: emailChangeHandler,
    onBlur: emailBlurHandler
  } = useInputValidation(
    (value)=>{if(validateEmailUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, email: value })},
    "Email tidak valid"
  )

  const {
    valueIsValid:passwordIsValid,
    errorValue:errorPassword,
    errMessageValue:errMessagePassword,
    onChange: passwordChangeHandler,
    onBlur: passwordBlurHandler
  } = useInputValidation(
    (value)=>{if(validatePasswordUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, password: value })},
    "Password min 6 dan max 16 karakter mengandung angka"
  )

  const {
    valueIsValid:typeIsValid,
    errorValue:errorType,
    errMessageValue:errMessageType,
    onChange: typeChangeHandler,
    onBlur: typeBlurHandler
  } = useDropdownValidation(
    (value)=>{if(value.length >0)return true},
    (value)=>{setObjectCreate({ ...objectCreate, type: value })},
    "Tidak boleh kosong"
  )

  const {
    valueIsValid:phoneNumberIsValid,
    errorValue:errorPhoneNumber,
    errMessageValue:errMessagePhoneNumber,
    onChange: phoneNumberChangeHandler,
    onBlur: phoneNumberBlurHandler
  } = useInputValidation(
    (value)=>{if(isNumberOnlyUtils(value))return true},
    (value)=>{setObjectCreate({ ...objectCreate, phone_number: value })},
    "Nomor Telepon hanya angka"
  )

  const adminStatus = [{ label: "Admin Dealer", value: "dealer" }];

  const breadcrumbItems = [
    {
      title: "Admin Management",
      to: "/admin-management",
    },
    {
      title: "Tambah Admin",
      noIcon: "noIcon",
    },
  ];

  const handleNext = () => {
    dispatch(bodyCreate(objectCreate));
    // previous
    // if (objectCreate.type === "admin") {
    //   history.push("/konfir-admin-management");
    // } else {
    //   history.push("/dealer-admin-management");
    // }

    history.push("/detail-dealer-admin-management")
  };

  // checking if all input is valid
  let formInvalid = true
  if (nikIsValid && passwordIsValid && emailIsValid && typeIsValid
      && phoneNumberIsValid && nameIsValid){
        formInvalid = false
  }

  let component = () => {
    return (
      <div>
        <AdminNav breadcrumbItems={breadcrumbItems} />

        <LayoutStockV2 style={{ padding: 20 }}>
          <Card.Content title="Informasi Admin">
            <div>
              <FormGroup>
                <Input
                  type="text"
                  label="NIK"
                  onChange={nikChangeHandler}
                  onBlur={nikBlurHandler}
                />
                {errorNik ? <label style={{color:"#ef4444"}}>{errMessageNik}</label> : null}
              </FormGroup>
            </div>

            <div className="mt-4">
              <FormGroup>
                <Input
                  type="text"
                  label="Nama Admin"
                  onChange={nameChangeHandler}
                  onBlur={nameBlurHandler}
                />
                {errorName ? <label style={{color:"#ef4444"}}>{errMessageName}</label> : null}
              </FormGroup>
            </div>

            <div className="form-size--half mt-4">
              <FormGroup>
                <Input
                  type="text"
                  label="Email Admin"
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                />
                {errorEmail ? <label style={{color:"#ef4444"}}>{errMessageEmail}</label> : null}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  label="Nomor Telepon"
                  onChange={phoneNumberChangeHandler}
                  onBlur={phoneNumberBlurHandler}
                />
                {errorPhoneNumber ? <label style={{color:"#ef4444"}}>{errMessagePhoneNumber}</label> : null}
              </FormGroup>
            </div>

            <div className="mb-4">
              <FormGroup>
                <Input
                  type="password"
                  label="Password"
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                />
                {errorPassword ? <label style={{color:"#ef4444"}}>{errMessagePassword}</label> : null}
              </FormGroup>
            </div>

            <div style={{ borderBottomWidth: 0, marginTop: "0.5em" }}>
              <FormGroup>
                <Dropdown
                  placeholder="Pilih Role"
                  label="Role"
                  items={adminStatus}
                  onChange={(value) =>{typeChangeHandler(value)}}
                  onClick={(value) =>{typeBlurHandler(value)}}
                  selectedItem={objectCreate.type}
                />
                {errorType ? <label style={{color:"#ef4444"}}>{errMessageType}</label> : null}
              </FormGroup>
            </div>

            <div
              className="form-size--one"
              style={{ borderBottomWidth: 0, marginTop: "2.5em" }}
            >
              <Button onClick={() => handleNext()} variant="full" disabled={formInvalid}>
                <span style={{ color: "#fff" }}>Selanjutnya</span>
              </Button>
            </div>
          </Card.Content>
        </LayoutStockV2>
      </div>
    );
  };
  return <Template noNavbar isSideNav title="Profile" component={component} />;
};

export default AddAdminManagement;
