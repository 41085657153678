import React from 'react'

import AdminNav from '../AdminNav'
import Button from '../Button'

import { LeftOutlined } from '@ant-design/icons';

import './styles.scss';

export default function LayoutStockV2({ children, isNavAdmin, title, header, handleAdd, handleBack }) {
    return (
        <div>
            {
                isNavAdmin && <AdminNav pageTitle={title} />
            }
            <div className='p-3'>
                {
                    handleBack && 
                    <div className="back-button" onClick={handleBack}><LeftOutlined /> Back</div>
                }
                <div className="box bg-white border-1 p-4 rounded mt-3">
                    <div className="d-flex justify-content-end">
                        {
                            handleAdd &&
                            <Button onClick={handleAdd}> Add </Button>
                        }
                    </div>
                    {children}
                </div>

            </div>
        </div>
    )
}
