import React from "react";
import { Card } from "react-bootstrap";
import DetailCover from "assets/img/detail_cover.png";
import "./../styles.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useNotificationBlastID } from "hooks/use-notification";

const DetailCard = () => {
  const { id } = useParams();

  const { data } = useNotificationBlastID(id);

  console.log("data", data);

  return (
    <Card className="notification__detail p-0">
      <div className="img-wrapper">
        <img src={DetailCover} className="cover" alt="detail cover" />
        <h2 className="detail-title">{data?.title}</h2>{" "}
      </div>{" "}
      <div className="p-5">
        <div>
          <p>{data?.body}</p>
          <br />
        </div>{" "}
        {/* <div className="product-wrapper">
                                              <Row>
                                                <Col md={3}>
                                                  <Card className="product-card">
                                                    <div>
                                                      <img src={Whell2} className="mx-auto thumbnail" alt="detail cover"/>
                                                    </div>
                                                    <div className="mt-3">
                                                      <h5 className="brand">Brand</h5>
                                                      <h4 className="name">Product Name</h4>
                                                    </div>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            </div> */}{" "}
        <div></div>{" "}
      </div>{" "}
    </Card>
  );
};

export default DetailCard;
