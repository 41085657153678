export const TransactionIcon =()=>{
    return(
        <svg viewBox="0 0 24 25" width="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 12.1569C6 11.3394 6 10.9306 6.15224 10.5631C6.30448 10.1955 6.59351 9.90649 7.17157 9.32843L7.82843 8.67157C8.40649 8.09351 8.69552 7.80448 9.06306 7.65224C9.4306 7.5 9.83935 7.5 10.6569 7.5H13.3431C14.1606 7.5 14.5694 7.5 14.9369 7.65224C15.3045 7.80448 15.5935 8.09351 16.1716 8.67157L16.8284 9.32843C17.4065 9.90649 17.6955 10.1955 17.8478 10.5631C18 10.9306 18 11.3394 18 12.1569V16.5C18 18.3856 18 19.3284 17.4142 19.9142C16.8284 20.5 15.8856 20.5 14 20.5H10C8.11438 20.5 7.17157 20.5 6.58579 19.9142C6 19.3284 6 18.3856 6 16.5V12.1569Z"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M9 7.5L6.13868 5.59245C6.0547 5.53647 5.9453 5.53647 5.86132 5.59245L3.25373 7.33084C3.12247 7.41835 3.10408 7.60408 3.21563 7.71563L5.79289 10.2929C5.9255 10.4255 6 10.6054 6 10.7929V10.7929C6 11.1834 6.31658 11.5 6.70711 11.5H17.2929C17.6834 11.5 18 11.1834 18 10.7929V10.7929C18 10.6054 18.0745 10.4255 18.2071 10.2929L20.7844 7.71563C20.8959 7.60408 20.8775 7.41835 20.7463 7.33084L18.1387 5.59245C18.0547 5.53647 17.9453 5.53647 17.8613 5.59245L15 7.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
        </svg>
    )
}

export const LogoutIcon=()=>{
  return(
    <svg width="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 12.0038L1.21913 11.3791L0.719375 12.0038L1.21913 12.6285L2 12.0038ZM11 13.0038C11.5523 13.0038 12 12.5561 12 12.0038C12 11.4515 11.5523 11.0038 11 11.0038V13.0038ZM5.21913 6.37909L1.21913 11.3791L2.78087 12.6285L6.78087 7.62848L5.21913 6.37909ZM1.21913 12.6285L5.21913 17.6285L6.78087 16.3791L2.78087 11.3791L1.21913 12.6285ZM2 13.0038H11V11.0038H2V13.0038Z" 
            fill="white"/>
      <path d="M10 8.13572V7.39229C10 5.77395 10 4.96478 10.474 4.40529C10.9479 3.8458 11.7461 3.71277 13.3424 3.44672L15.0136 3.16818C18.2567 2.62767 19.8782 2.35741 20.9391 3.25611C22 4.1548 22 5.79872 22 9.08655V14.921C22 18.2089 22 19.8528 20.9391 20.7515C19.8782 21.6502 18.2567 21.3799 15.0136 20.8394L13.3424 20.5609C11.7461 20.2948 10.9479 20.1618 10.474 19.6023C10 19.0428 10 18.2336 10 16.6153V16.0698" 
            stroke="white" strokeWidth="2"/>
    </svg>
  )
}

export const CheckedBox =()=>{
  return(
    <svg width="18" height="18" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0.00378418H2C0.9 0.00378418 0 0.903784 0 2.00378V16.0038C0 17.1038 0.9 18.0038 2 18.0038H16C17.1 18.0038 18 17.1038 18 16.0038V2.00378C18 0.903784 17.1 0.00378418 16 0.00378418ZM7.71 13.2938C7.32 13.6838 6.69 13.6838 6.3 13.2938L2.71 9.70378C2.32 9.31378 2.32 8.68378 2.71 8.29378C3.1 7.90378 3.73 7.90378 4.12 8.29378L7 11.1738L13.88 4.29378C14.27 3.90378 14.9 3.90378 15.29 4.29378C15.68 4.68378 15.68 5.31378 15.29 5.70378L7.71 13.2938Z" fill="#455673"/>
    </svg>
  )
}