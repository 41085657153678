import React from "react";
import classNames from "classnames";

import { Button } from "..";

import "./styles.scss";

const Modal = ({
  title,
  description,
  isModalActive,
  onModalClosed,
  onModalConfirmed,
  type,
  buttonColor,
  buttonText,
  isLoading,
}) => {
  const toggleModal = () => {
    if (!isLoading) {
      onModalClosed();
    }
  };

  return (
    <>
      {isModalActive && (
        <div
          className={classNames("modal", {
            "modal--confirm": type === "confirm",
            "modal--show": !isLoading
          })}
        >
          <div
            style={
              type === "confirm" ? { backgroundColor: "#AF1A00" } : { backgroundColor: "#E9522A" }
            }
            className="modal__header"
          />
          <div className="modal__body">
            <h1 style={type === "confirm" ? { color: "#AF1A00" } : { color: "#E9522A" }}>
              {type === "confirm" ? "PERHATIAN" : type === "Process" ? "KONFIRMASI" : "BERHASIL"}
            </h1>

            {type === "confirm" ? (
              <svg
                width="152"
                height="152"
                viewBox="0 0 152 152"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M82.2482 121.277C106.827 121.277 126.753 101.668 126.753 77.4776C126.753 53.2876 106.827 33.6777 82.2482 33.6777C57.6689 33.6777 37.7434 53.2876 37.7434 77.4776C37.7434 101.668 57.6689 121.277 82.2482 121.277Z"
                  fill="#AF1A00"
                />
                <path
                  d="M76.0856 121.046C100.665 121.046 120.59 100.872 120.59 75.9868C120.59 51.1014 100.665 30.9277 76.0856 30.9277C51.5063 30.9277 31.5808 51.1014 31.5808 75.9868C31.5808 100.872 51.5063 121.046 76.0856 121.046Z"
                  stroke="#1A2D47"
                  strokeWidth="5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M82.2698 53V88.3795H71.7843V53H82.2698ZM77.0627 93.5866C78.8222 93.5866 80.2725 94.0859 81.4138 95.0845C82.5551 96.1307 83.1257 97.4621 83.1257 99.0789C83.1257 100.696 82.5551 102.003 81.4138 103.002C80.2725 104.001 78.8222 104.5 77.0627 104.5C75.2557 104.5 73.8053 104.001 72.7116 103.002C71.5703 102.003 70.9997 100.696 70.9997 99.0789C70.9997 97.4621 71.5703 96.1307 72.7116 95.0845C73.8053 94.0859 75.2557 93.5866 77.0627 93.5866Z"
                  fill="white"
                />
                <path
                  d="M132.613 76.3035H151"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M76.5111 1.18287V19.3894"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M76.5111 132.793V151"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.4207 23.3301L36.4221 36.204"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M116.923 115.972L129.925 128.846"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M129.707 23.1182L116.705 35.9922"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M102.033 16.2973L97.9616 23.2804"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M98.4029 128.684L102.475 135.668"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M50.4394 16.2987L54.511 23.2818"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M54.511 128.846L50.4394 135.829"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.6786 98.1804L15.8129 100.267"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.5875 54.2732L15.7217 52.1862"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M136.545 52.1862L128.679 54.2732"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M136.672 100.267L128.806 98.1804"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M35.7604 116.196L22.759 129.07"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.48703 76.3035H19.8738"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : type === "Process" ? (
              <svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M82.2509 121.277C106.83 121.277 126.756 101.668 126.756 77.4776C126.756 53.2876 106.83 33.6777 82.2509 33.6777C57.6716 33.6777 37.7461 53.2876 37.7461 77.4776C37.7461 101.668 57.6716 121.277 82.2509 121.277Z" fill="#E9522A"/>
              <path d="M76.0868 121.046C100.666 121.046 120.592 100.872 120.592 75.9868C120.592 51.1014 100.666 30.9277 76.0868 30.9277C51.5075 30.9277 31.582 51.1014 31.582 75.9868C31.582 100.872 51.5075 121.046 76.0868 121.046Z" stroke="#1A2D47" stroke-width="5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M82.269 53V88.3795H71.7836V53H82.269ZM77.062 93.5866C78.8214 93.5866 80.2718 94.0859 81.4131 95.0845C82.5544 96.1307 83.125 97.4621 83.125 99.0789C83.125 100.696 82.5544 102.003 81.4131 103.002C80.2718 104.001 78.8214 104.5 77.062 104.5C75.255 104.5 73.8046 104.001 72.7109 103.002C71.5696 102.003 70.999 100.696 70.999 99.0789C70.999 97.4621 71.5696 96.1307 72.7109 95.0845C73.8046 94.0859 75.255 93.5866 77.062 93.5866Z" fill="white"/>
              <path d="M132.615 76.3035H151.002" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M76.5136 1.18287V19.3894" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M76.5136 132.793V151" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.4205 23.3301L36.4219 36.204" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M116.924 115.972L129.926 128.846" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M129.707 23.1182L116.706 35.9922" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M102.033 16.2973L97.9615 23.2804" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M98.4029 128.684L102.475 135.668" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M50.442 16.2987L54.5136 23.2818" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M54.5136 128.846L50.442 135.829" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.6799 98.1804L15.8141 100.267" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.5883 54.2732L15.7225 52.1862" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M136.547 52.1862L128.681 54.2732" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M136.674 100.267L128.808 98.1804" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M35.7617 116.196L22.7603 129.07" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.4902 76.3035H19.877" stroke="#F5F5F5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              
            ) : (
              <svg
                width="152"
                height="152"
                viewBox="0 0 152 152"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M82.2484 121.277C106.828 121.277 126.753 101.668 126.753 77.4776C126.753 53.2876 106.828 33.6777 82.2484 33.6777C57.6691 33.6777 37.7437 53.2876 37.7437 77.4776C37.7437 101.668 57.6691 121.277 82.2484 121.277Z"
                  fill="#E9522A"
                />
                <path
                  d="M76.0858 121.046C100.665 121.046 120.591 100.872 120.591 75.9868C120.591 51.1014 100.665 30.9277 76.0858 30.9277C51.5065 30.9277 31.5811 51.1014 31.5811 75.9868C31.5811 100.872 51.5065 121.046 76.0858 121.046Z"
                  stroke="#1A2D47"
                  strokeWidth="5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M68.747 96.131C67.8113 97.0642 66.3128 97.0596 65.3706 96.1327C61.3845 92.2115 51.6107 82.5487 49.4551 79.9667C47.3684 77.4673 50.3013 74.8069 52.658 76.7239C55.009 78.6363 64.1106 88.1944 66.4958 90.7092C66.8112 91.0417 67.3228 91.0563 67.642 90.7273C71.7233 86.5207 96.4572 61.0531 102.074 55.8775C104.239 53.8822 107.346 56.844 105.277 59.1203C99.9994 64.9236 75.4213 89.4743 68.747 96.131Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M108.775 1L110.61 6.08828L115.567 7.97101L110.61 9.85391L108.775 14.942L106.941 9.85391L101.984 7.97101L106.941 6.08828L108.775 1Z"
                  fill="#455673"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.79153 40.8672L9.62577 45.9555L14.5831 47.8382L9.62577 49.7211L7.79153 54.8092L5.95728 49.7211L1 47.8382L5.95728 45.9555L7.79153 40.8672Z"
                  fill="#455673"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.8439 65.8936L12.3775 67.6696L14.1078 68.2172L12.3775 68.7649L11.8439 70.5409L11.3103 68.7649L9.58008 68.2172L11.3103 67.6696L11.8439 65.8936Z"
                  fill="#455673"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M84.4484 6.11914L84.982 7.89516L86.7122 8.44281L84.982 8.99052L84.4484 10.7665L83.9148 8.99052L82.1846 8.44281L83.9148 7.89516L84.4484 6.11914Z"
                  fill="#455673"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M142.31 68.8887L142.844 70.6647L144.574 71.2123L142.844 71.76L142.31 73.536L141.777 71.76L140.046 71.2123L141.777 70.6647L142.31 68.8887Z"
                  fill="#455673"
                />
                <path
                  d="M145.705 54.1984C147.372 54.1984 148.723 52.8113 148.723 51.1002C148.723 49.3891 147.372 48.002 145.705 48.002C144.038 48.002 142.687 49.3891 142.687 51.1002C142.687 52.8113 144.038 54.1984 145.705 54.1984Z"
                  fill="#455673"
                />
                <path
                  d="M42.9608 9.4025C44.6279 9.4025 45.9793 8.01538 45.9793 6.30428C45.9793 4.59318 44.6279 3.20605 42.9608 3.20605C41.2938 3.20605 39.9424 4.59318 39.9424 6.30428C39.9424 8.01538 41.2938 9.4025 42.9608 9.4025Z"
                  fill="#455673"
                />
                <path
                  d="M32.175 24.6439C32.6238 24.6439 32.9876 24.2704 32.9876 23.8097C32.9876 23.349 32.6238 22.9756 32.175 22.9756C31.7261 22.9756 31.3623 23.349 31.3623 23.8097C31.3623 24.2704 31.7261 24.6439 32.175 24.6439Z"
                  fill="#455673"
                />
                <path
                  d="M123.699 39.431C124.148 39.431 124.512 39.0575 124.512 38.5968C124.512 38.1362 124.148 37.7627 123.699 37.7627C123.251 37.7627 122.887 38.1362 122.887 38.5968C122.887 39.0575 123.251 39.431 123.699 39.431Z"
                  fill="#455673"
                />
                <path
                  d="M132.613 76.3035H151"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M76.5112 1.18287V19.3894"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M76.5112 132.793V151"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.4205 23.3301L36.4219 36.204"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M116.923 115.972L129.925 128.846"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M129.707 23.1182L116.705 35.9922"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M102.033 16.2973L97.9615 23.2804"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M98.4029 128.684L102.475 135.668"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M50.439 16.2987L54.5107 23.2818"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M54.5107 128.846L50.439 135.829"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.6784 98.1804L15.8126 100.267"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.5873 54.2732L15.7215 52.1862"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M136.545 52.1862L128.679 54.2732"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M136.671 100.267L128.805 98.1804"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M35.7603 116.196L22.7588 129.07"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.48679 76.3035H19.8736"
                  stroke="#F5F5F5"
                  strokeMiterlimit="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}

            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          {type === "confirm" && (
            <div className="modal__actions">
              <Button
                widthVariant="full"
                textVariant="big"
                variant="secondary"
                onClick={onModalClosed}
                disabled={isLoading}
              >
                Batal
              </Button>
              <Button
                colorVariant={buttonColor}
                widthVariant="full"
                textVariant="big"
                onClick={onModalConfirmed}
                isLoading={isLoading}
                disabled={isLoading}
              >
                {buttonText}
              </Button>
            </div>
          )}
          {type === "Process" && (
            <div className="modal__actions">
              <Button
                widthVariant="full"
                textVariant="big"
                variant="secondary"
                onClick={onModalClosed}
                disabled={isLoading}
              >
                Batal
              </Button>
              <Button
                colorVariant={buttonColor}
                widthVariant="full"
                textVariant="big"
                onClick={onModalConfirmed}
                isLoading={isLoading}
                disabled={isLoading}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      )}

      {isModalActive && <div className="modal-overlay" onClick={toggleModal} />}
    </>
  );
};

export default Modal;