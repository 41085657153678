import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Trash2 } from "react-feather";
import ImageUploading from "react-images-uploading";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";

import {
  AdminNav,
  Button,
  Card,
  FormGroup,
  Input,
  Modal,
} from "../../components";
import Template from "../shared/Template";
import { createAddOns, detailAddOns, updateAddOns } from "./addOns.api";
import { uploadFile } from "../../services/fileService";
import { useHistory, useParams } from "react-router-dom";
import { Switch } from "antd";

const AddOnsForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idAddOns } = useParams();

  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      description: "",
      url_photo: [],
      // status: "active",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama add ons is required"),
      price: Yup.string().required("Harga is required"),
      description: Yup.string().required("Description is required"),
      // url_photo: Yup.array().min(1, "At least one photo is required"),
    }),
    onSubmit: (values) => {
      if (clickSubmit) {
        setLoading(true);
        const decodeToken = jwt_decode(localStorage.getItem("accessToken"));

        if (Array.isArray(values.url_photo) && values.url_photo.length > 0) {
          const payloadFile = {
            file: values.url_photo[0].file,
            path: "dealer",
            overwrite: false,
            previous_file: "",
          };

          dispatch(uploadFile(payloadFile)).then((response) => {
            values.dealer_id = decodeToken.dealerId;
            values.url_photo = response.payload.data.file_url;

            if (idAddOns !== undefined) {
              values.idAddOns = idAddOns;
            }

            dispatch(
              idAddOns === undefined
                ? createAddOns(values)
                : updateAddOns(values)
            ).then((res) => {
              if (res.payload.code === 201 || res.payload.code === 200) {
                setIsSuccessModalActive(true);
                values.url_photo = null;
              } else {
                alert(
                  "Terjadi kesalahan, Gagal menghapus add ons dari daftar warehouse!"
                );
              }

              setLoading(false);
            });
          });
        } else {
          values.dealer_id = decodeToken.dealerId;

          if (idAddOns === undefined) {
            values.url_photo = null;
          } else {
            values.idAddOns = idAddOns;
          }

          dispatch(
            idAddOns === undefined ? createAddOns(values) : updateAddOns(values)
          ).then((res) => {
            if (res.payload.code === 201 || res.payload.code === 200) {
              setIsSuccessModalActive(true);
            } else {
              alert(
                "Terjadi kesalahan, Gagal menghapus add ons dari daftar warehouse!"
              );
            }
            setLoading(false);
          });
        }
      }
    },
  });

  const { handleSubmit } = formik;

  const breadcrumbItems = [
    {
      title: "Add Ons Management",
      to: "/add-ons-management",
    },
    {
      title: `${idAddOns === undefined ? "Tambah" : "Edit"} Add Ons`,
      to: "/add-ons-management/create",
      noIcon: "noIcon",
    },
  ];

  useEffect(() => {
    if (idAddOns !== undefined) {
      dispatch(detailAddOns(idAddOns)).then((res) => {
        const resData = res.payload.data;

        formik.setFieldValue("name", resData.name);
        formik.setFieldValue("price", resData.price);
        formik.setFieldValue("description", resData.description);
        // formik.setFieldValue("status", resData.status);
        formik.setFieldValue("url_photo", resData.url_photo);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idAddOns]);

  console.log("cek", idAddOns);

  const component = () => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="add-ons-management">
            <AdminNav
              pageTitle="Add Ons Management"
              breadcrumbItems={breadcrumbItems}
            />

            <div className="d-flex justify-content-between mt-5">
              <Card className="mx-4">
                <Card.Content title="Upload Foto">
                  {formik.errors.url_photo ? (
                    <div className="label-validation-error">
                      {formik.errors.url_photo}
                    </div>
                  ) : null}
                  <ImageUploading
                    value={
                      formik.values.url_photo !== null
                        ? formik.values.url_photo[0]
                        : null
                    }
                    onChange={(val) => formik.setFieldValue("url_photo", val)}
                    dataURLKey="data_url"
                  >
                    {({
                      onImageUpload,
                      onImageRemoveAll,
                      isDragging,
                      dragProps,
                    }) => (
                      <div
                        style={{
                          minHeight: "30%",
                          minWidth: "30%",
                        }}
                      >
                        <div
                          className="upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center"
                          style={{
                            minHeight: 250,
                            minWidth: "30%",
                            border: "1px solid #DADADA",
                            borderRadius: "10px",
                          }}
                        >
                          {formik.values.url_photo === null ||
                          formik.values.url_photo.length === 0 ? (
                            <svg
                              width="72"
                              height="72"
                              viewBox="0 0 72 72"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 29C9 19.5719 9 14.8579 11.9289 11.9289C14.8579 9 19.5719 9 29 9H43C52.4281 9 57.1421 9 60.0711 11.9289C63 14.8579 63 19.5719 63 29V43C63 52.4281 63 57.1421 60.0711 60.0711C57.1421 63 52.4281 63 43 63H29C19.5719 63 14.8579 63 11.9289 60.0711C9 57.1421 9 52.4281 9 43V29Z"
                                stroke="#DADADA"
                                strokeWidth="5"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 41.4639L8.73242 41.7315L9 41.9991V41.4639ZM58 49.5346L51.0769 42.6115C49.7322 41.2668 49.4279 40.9999 49.1869 40.8699C48.4462 40.4704 47.5542 40.4704 46.8135 40.8699C46.5725 40.9999 46.2681 41.2668 44.9235 42.6115L44.8734 42.6616L44.8733 42.6617C44.0564 43.4787 43.3697 44.1654 42.7828 44.6895C42.1841 45.2242 41.5364 45.7325 40.7736 46.0769C37.8821 47.3827 34.4818 46.7279 32.2822 44.4417C31.7018 43.8385 31.2892 43.1261 30.9319 42.4073C30.5817 41.7026 30.1992 40.81 29.7441 39.7482L29.744 39.7479L29.7161 39.6829L29.2023 38.4841C28.4755 36.7881 27.9896 35.6595 27.557 34.8437C27.1264 34.0317 26.8882 33.8114 26.7913 33.7403C26.187 33.2976 25.4161 33.1491 24.6906 33.3358C24.5742 33.3657 24.2712 33.4819 23.5699 34.0758C22.8652 34.6726 21.9949 35.5401 20.6902 36.8448L13 44.535V37.4639L17.1547 33.3092L17.2294 33.2345L17.2294 33.2345C18.4396 32.0242 19.4565 31.0073 20.3385 30.2603C21.2419 29.4952 22.2376 28.8041 23.4445 28.4935C25.6209 27.9334 27.9338 28.3789 29.7466 29.7072C30.7518 30.4438 31.4196 31.4552 31.9743 32.5011C32.5158 33.5222 33.0822 34.8441 33.7564 36.4173L33.7564 36.4173L33.798 36.5145L34.3118 37.7133C34.8022 38.8575 35.1296 39.6189 35.4093 40.1818C35.6892 40.745 35.8325 40.9202 35.8852 40.975C36.6185 41.737 37.7519 41.9553 38.7157 41.5201C38.7849 41.4888 38.9831 41.3793 39.4521 40.9604C39.9209 40.5417 40.5076 39.9563 41.3879 39.076L41.6074 38.8562C42.6032 37.858 43.4632 36.996 44.44 36.4692C46.6622 35.2707 49.3382 35.2707 51.5604 36.4692C52.5372 36.996 53.3972 37.858 54.393 38.8562L54.6125 39.076L58 42.4635V49.5346Z"
                                fill="#DADADA"
                              />
                              <circle
                                cx="49.5"
                                cy="22.5"
                                r="4.5"
                                fill="#DADADA"
                              />
                            </svg>
                          ) : null}

                          {formik.values.url_photo !== null &&
                          !Array.isArray(formik.values.url_photo) ? (
                            <img
                              key={formik.values.url_photo}
                              src={formik.values.url_photo}
                              alt={`res-${formik.values.url_photo}`}
                              style={{ borderRadius: "10px" }}
                            />
                          ) : (
                            formik.values.url_photo !== null &&
                            formik.values.url_photo.map((image, index) => (
                              <img
                                key={index}
                                src={image["data_url"]}
                                alt={`res-${index}`}
                                style={{ borderRadius: "10px" }}
                              />
                            ))
                          )}
                        </div>

                        <div className="mt-5 d-flex align-items-center justify-content-between">
                          <Button
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Upload
                          </Button>

                          <div
                            className="d-flex"
                            style={{
                              fontWeight: "bold",
                              color: "#455673",
                              cursor: "pointer",
                            }}
                            onClick={() => onImageRemoveAll()}
                          >
                            <Trash2 /> &nbsp; Hapus
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </Card.Content>
              </Card>

              <Card className="mx-4">
                <Card.Content title="Informasi Add Ons">
                  <FormGroup>
                    <Input
                      type="text"
                      id="name"
                      label="Nama Add Ons"
                      name="name"
                      onChange={formik.handleChange}
                      placeholder="Masukkan nama add ons"
                      value={formik.values.name}
                    />
                    {formik.errors.name ? (
                      <div className="label-validation-error">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <Input
                      type="number"
                      id="price"
                      label="Harga"
                      name="price"
                      onChange={formik.handleChange}
                      placeholder="Masukkan harga add ons"
                      value={formik.values.price}
                    />
                    {formik.errors.price ? (
                      <div className="label-validation-error">
                        {formik.errors.price}
                      </div>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <Input
                      type="textarea"
                      id="description"
                      label="Description"
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      placeholder="Masukkan deskripsi"
                    />
                    {formik.errors.description ? (
                      <div className="label-validation-error">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </FormGroup>

                  {/*                   {idAddOns !== undefined ? (
                    <FormGroup>
                      <div style={{ color: "#E9522A", fontWeight: "bold" }}>
                        Status Active
                      </div>
                      <Switch
                        checked={formik.values.status === "active"}
                        onChange={(checked) =>
                          formik.setFieldValue(
                            "status",
                            checked ? "active" : "inactive"
                          )
                        }
                        style={{
                          backgroundColor:
                            formik.values.status === "active"
                              ? "#E9522A"
                              : "lightgray",
                        }}
                      />
                    </FormGroup>
                  ) : null} */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button type="submit" onClick={() => setClickSubmit(true)}>
                      {loading ? "Loading..." : "Simpan"}
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            </div>
          </div>
        </form>

        {console.log("cek", idAddOns === undefined)}

        <Modal
          type="success"
          title={`Add Ons ${
            idAddOns === undefined ? "Created" : "Update"
          } Successfully`}
          description={`Add Ons telah berhasil ${
            idAddOns === undefined ? "ditambahkan" : "diubah"
          }`}
          isModalActive={isSuccessModalActive}
          onModalClosed={() => history.push("/add-ons-management")}
          isLoading={false}
        />

        {/* <Modal
          type="Process"
          title={`False`}
          description={`Terjadi kesalahan tidak Boleh ada data kosong`}
          isModalActive={isNotSuccessModalActive}
          onModalClosed={() => history.push("/")}
          isLoading={false}
        /> */}
      </>
    );
  };

  return <Template noNavbar isSideNav title="Profile" component={component} />;
};
export default AddOnsForm;
